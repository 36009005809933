import * as React from 'react';
import { IContextualMenuItem } from 'office-ui-fabric-react';
import ColorStageView, { getStageFieldLabel } from './ColorStageView';
import { EntityType } from '../../../../entities/common';

interface Props {
    entityType: EntityType;
    map: {
        [i: string]: { title: string, cssClassName: string, iconName?: string }
    },
    value?: string | number;
    className?: string;
    subMenuItems?: IContextualMenuItem[];
}

const StageView = ({ map, value, className, subMenuItems, entityType }: Props) => {
    const config = value != undefined ? map[value] : undefined;
    return config || value !== undefined
        ? <ColorStageView
            className={config ? config.cssClassName : ''}
            iconName={config && config.iconName}
            fieldLabel={ getStageFieldLabel(entityType) }
            rootClassName={className}
            title={config ? config.title : value?.toString()}
            subMenuItems={subMenuItems}
        />
        : null;
}

export default StageView;