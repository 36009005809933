import React, { useState, useCallback } from 'react'
import { Link, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import EntityPicker from '../../common/inputs/EntityPicker';
import { IFindResult } from '../../common/inputs/EntityPickerInput';
import { ServerEntityType } from '../../../entities/common';
import { IWithProcess, ProcessInfo } from '../../../store/ProjectsListStore';
import LabellableComponent from '../../common/LabellableComponent';
import TextInput from '../../common/inputs/TextInput';
import { ProcessStatus } from '../../../store/process/common';
import { Subscription } from '../../../store/Tenant';
import { ApplicationState } from '../../../store';
import { connect } from 'react-redux';
import { getApiBasedOptionState } from '../../process/ApprovalForTransitions';

type OwnProps = {
    readonly?: boolean,
    process: ProcessInfo;
    value: Partial<IWithProcess>;
    onChange: (value: Partial<IWithProcess>) => void;
};

type StateProps = {
    subscription: Subscription;
};

type Props = OwnProps & StateProps;

const ProjectProcessSection = (props: Props) => {
    const { readonly, value, process, subscription, onChange } = props;
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [processInfo, setProcessInfo] = useState(value.process);    

    React.useEffect(() => {
        if (!showConfirmation && processInfo?.id !== value.process?.id) {
            setProcessInfo(value.process)
        }
    }, [showConfirmation, processInfo, value.process])

    const onEditComplete = useCallback((result: IFindResult) => {
        if (!result) {
            onChange({ ...value, process: undefined });
            return;
        }

        setProcessInfo({ id: result.id, name: result.name, status: ProcessStatus.Active }); 
        
        if (result.id !== process.id) {
            setShowConfirmation(true);
        } else {
            onChange({ ...value, process });
        }
    }, [onChange, process, value]);

    const apiBasedOptionState = getApiBasedOptionState(subscription);
    const apiBasedApprovalReadonly = readonly || apiBasedOptionState.disabled;

    return (
        <>
            <div className='section-description'>
                Select the process for the current project and set up an approval workflow. Please refer to
                <Link href="https://help.ppm.express/94162-portfolio-and-project-management/2082039-project-settings#section-7" target="_blank"> this article</Link> for more details.
            </div>
            {processInfo?.status === ProcessStatus.Inactive && <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                The selected process is inactive
            </MessageBar>}
            <LabellableComponent className="field-container" label="Process" required>
                <EntityPicker
                    value={processInfo}
                    searchPath="api/process/find"
                    filter={{ entityType: ServerEntityType.Project }}
                    readOnly={readonly}
                    disabled={readonly}
                    inputProps={{ required: true }}
                    onEditComplete={onEditComplete}
                />
            </LabellableComponent>
            <LabellableComponent className="field-container" label="API-Based Approval Workflow URL" description="Specify the URL of the external API-based approval workflow that will be triggered when the 'Go to the next stage' action is clicked for stages with 'Approval for Transition' set to 'API-based Approval'">
                <TextInput
                    value={value.apiBasedApprovalUrl}
                    disabled={apiBasedApprovalReadonly}
                    readOnly={apiBasedApprovalReadonly}
                    inputProps={{ title: apiBasedOptionState.title }}
                    onChanged={_ => onChange({ process: value.process, apiBasedApprovalUrl: _ || undefined })}
                />
            </LabellableComponent>
            {showConfirmation && processInfo &&
                <ConfirmationDialog
                    dialogContentProps={{
                        isMultiline: true,
                        title: "Changing Project Process",
                        subText: `Are you sure you want to change the Project Process? `
                    }}
                    onNo={() => setProcessInfo(value.process)}
                    onDismiss={() => setShowConfirmation(false)}
                    onYes={() => onChange({ process: processInfo, apiBasedApprovalUrl: value.apiBasedApprovalUrl })}
                >
                    <MessageBar messageBarType={MessageBarType.warning}>
                        The project process will be reset to the first stage.
                    </MessageBar>
                </ConfirmationDialog>}
        </>);
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        subscription: state.tenant.subscription,
    };
}

export default connect(mapStateToProps)(ProjectProcessSection);
