import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from "react-router-dom";
import FilterPanel from "./FilterPanel";
import { ApplicationState } from '../../store';
import * as FiltersStore from '../../store/filters';
import * as Metadata from "../../entities/Metadata";
import { EntityType } from '../../entities/common';
import ListMenuFilterSelector from './ListMenuFilterSelector';

type OwnProps<T> = {
    canManageConfiguration: boolean,
    preFilter?: Metadata.PreFilter<T>;
    preFilterId?: string;
    activeFilter?: Metadata.IFilter<Metadata.BaseFilterValue>;
    onActiveFilterChanged: (id?: string) => void;
    onFilterChanged: (filter: Metadata.IFilter<Metadata.BaseFilterValue>) => void;
    isFilterPanelOpen: boolean;
    toggleFilterPanel: () => void;
    fields?: Metadata.Field[];
    filters?: Metadata.IFilter<Metadata.BaseFilterValue>[];
    entityType: EntityType,
    entityFilterHelper: Metadata.IEntityFilterHelper<T>;

    suppressNavigation?: boolean;
}

type StoreProps = {
    filters: Metadata.IFilter<Metadata.BaseFilterValue>[];
    fields: Metadata.Field[];
}

type ActionProps = {
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
}

type Props<T> = OwnProps<T> & StoreProps & ActionProps & RouteComponentProps<any>;

class EntitiesFilter<T> extends React.Component<Props<T>> {
    componentWillMount() {
        this._setActiveFilter(this.props);
    }

    componentWillReceiveProps(nextProps: Props<T>) {
        this._setActiveFilter(nextProps);
    }

    private _setActiveFilter(props: Props<T>) {
        if (props.suppressNavigation) {
            return;
        }

        const filters = props.filters;
        if (!filters || !filters.length) {
            return;
        }

        const activeFilter = props.activeFilter;

        const stateFilter = props.location.state as Metadata.IFilter<Metadata.BaseFilterValue>;
        if (stateFilter && stateFilter != activeFilter) {
            props.onFilterChanged(stateFilter);
            this.props.history.replace({ search: `?filter=${stateFilter.id}` });
            return;
        }

        const query = new URLSearchParams(props.location.search);
        const urlFilterId = query.get("filter");

        if (urlFilterId) {
            if (activeFilter?.id === urlFilterId) {
                return;
            }

            const filter = filters.find(_ => _.id === urlFilterId);
            if (filter) {
                props.onActiveFilterChanged(filter.id);
            } else if (urlFilterId == Metadata.NEW_ID && activeFilter) {
                props.history.replace({ search: `?filter=${activeFilter.id}` });
            } else {
                this._setAutoFilter(filters);
            }
            return;
        }

        if (activeFilter) {
            props.history.replace({ search: `?filter=${activeFilter.id}` });
            return;
        }

        this._setAutoFilter(filters);
    }

    private _setAutoFilter(filters: Metadata.IFilter<Metadata.BaseFilterValue>[]) {
        this.props.history.replace({ search: `?filter=${Metadata.Filter.getAutoFilterId(filters)}` });
    }

    public render() {
        const { preFilter, activeFilter, filters, filtersActions, canManageConfiguration, isFilterPanelOpen, preFilterId, onFilterChanged, toggleFilterPanel } = this.props;
        return <>
            <ListMenuFilterSelector
                canManageConfiguration={canManageConfiguration}
                preFilter={preFilter}
                preFilterId={preFilterId}
                activeFilter={activeFilter}
                filters={filters}
                onActiveItemChanged={this._setFilter}
                onItemAddClick={() => {
                    const newFilter = this.props.entityFilterHelper.newFilter("New Filter").build();
                    onFilterChanged(newFilter);
                    this._setFilter(newFilter.id)
                    toggleFilterPanel();
                }}
                onItemEditClick={id => {
                    if (id != activeFilter?.id) {
                        this._setFilter(id);
                    }
                    toggleFilterPanel();
                }}
                onItemRemoveClick={id => {
                    this._setFilter(Metadata.Filter.getAutoFilterId(filters)!);
                    filtersActions.removeFilter(id);
                }}
                onItemCopyClick={(filter: Metadata.IFilter<Metadata.BaseFilterValue>) => {
                    toggleFilterPanel();
                    filtersActions.saveFilter(Metadata.Filter.copy(filter), true);
                }}
            />
            {isFilterPanelOpen && activeFilter && (
                <FilterPanel
                    filter={activeFilter}
                    onFilterChanged={onFilterChanged}
                    onSave={() => filtersActions.saveFilter(activeFilter, _ => this._setFilter(_.id))}
                    onCopy={_ => filtersActions.saveFilter(_, (newFilter) => this._setFilter(newFilter.id))}
                    onDismiss={() => toggleFilterPanel()}
                    canManageConfiguration={canManageConfiguration}
                    fields={this.props.fields}
                    entityFilterHelper={this.props.entityFilterHelper}
                />
            )}
        </>;
    }

    private _setFilter = (id: string) => {
        if (this.props.suppressNavigation) {
            this.props.onActiveFilterChanged(id);
        } else {
            const query = new URLSearchParams(this.props.history.location.search);
            query.set('filter', id);
            this.props.history.push({ search: query.toString() });
        }
    }
}

export default function <T>() {
    function mapStateToProps(state: ApplicationState, ownProps: OwnProps<T>): StoreProps {
        const fields = ownProps.fields ? undefined : state.fields[ownProps.entityType];
        const filters = ownProps.filters ? undefined : state.filters[ownProps.entityType];

        return {
            fields: ownProps.fields ?? fields!.allIds.map(_ => fields!.byId[_]),
            filters: ownProps.filters ?? filters!.allIds.map(_ => filters!.byId[_]),
        };
    }

    function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps<T>): ActionProps {
        return {
            filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(ownProps.entityType), dispatch)
        };
    }

    return withRouter<OwnProps<T>>(
        connect(mapStateToProps, mapDispatchToProps)(EntitiesFilter as new (props: Props<T>) => EntitiesFilter<T>)
    );
}