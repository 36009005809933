import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { UserState, isInReadonlyMode } from '../../store/User';
import { canCreate, canUpdate } from '../../store/permissions';
import { IconButton, IContextualMenuItem, mapEnumByName } from 'office-ui-fabric-react';
import { ILayoutActions, LayoutService } from '../utils/LayoutService';
import { EntityType } from '../../entities/common';
import { TenantState } from '../../store/Tenant';
import { notUndefined } from '../utils/common';
import SharePanel from '../common/SharePanel';
import { LayoutsState } from '../../store/layouts';
import PersistEntityViewMenu from '../common/PersistEntityViewMenu';
import EntityHeader from '../common/EntityHeader';
import RemoveDialog from '../common/RemoveDialog';
import Logo from '../common/Logo';
import { Roadmap, RoadmapStage, roadmapStageMap } from '../../store/roadmap/common';
import { RoadmapSummaryWarning } from './RoadmapSummaryWarning';
import StageView from '../views/list/columns/StageView';
import { Layout } from '../../entities/Metadata';
import ApplyLayoutConfirmationDialog from '../common/ApplyLayoutConfirmationDialog';
import EntityHeaderMoreMenu from '../common/EntityHeaderMoreMenu';

interface IActions {
    cloneRoadmap: () => void;
    removeRoadmap: () => void;
    updateRoadmapStage: (stage: RoadmapStage) => void;
    layoutActions: ILayoutActions;
}

type OwnProps = {
    entity: Roadmap;
    actions: IActions;
}

type StateProps = {
    user: UserState;
    tenant: TenantState;
    layouts: LayoutsState;
}
type Props = OwnProps & StateProps & RouteComponentProps<{}>;

const RoadmapHeader = (props: Props) => {
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
    const [layoutToApply, setLayoutToApply] = React.useState<Layout>();

    const { entity, tenant, layouts, user, actions } = props;

    const canClone = entity.isEditable && canCreate(user.permissions.roadmap);

    const stagesMenu = entity.isEditable
        ? mapEnumByName(RoadmapStage, (name, value) => {
            const stage = Number(value);
            return {
                key: stage.toString(),
                data: stage,
                name: roadmapStageMap[stage].title,
                onClick: () => actions.updateRoadmapStage(stage)
            };
        })!.filter(notUndefined)
        : undefined

    const moreMenuItems: IContextualMenuItem[] = [
        entity.canConfigure && actions.layoutActions?.applyLayout
            ? {
                ...LayoutService.buildApplyLayoutMenuItem(layouts, (layout) => setLayoutToApply(layout), entity.layoutId),
                disabled: !entity.isEditable
            }
            : undefined,
        {
            key: 'cloneRoadmap',
            iconProps: { iconName: 'Copy' },
            name: 'Clone Roadmap',
            disabled: !canClone,
            onClick: () => actions.cloneRoadmap()
        },
        {
            key: 'deleteRoadmap',
            iconProps: { iconName: 'Delete' },
            name: 'Delete Roadmap',
            className: 'more-deleteButton',
            disabled: !entity.isEditable,
            onClick: () => setIsRemoveDialogOpen(true)
        }
    ].filter(notUndefined);
    const readonlyMode = isInReadonlyMode(user, tenant);
    const _isMoreMenuDisabled = () => {
        if (!props.entity.isEditable) {
            return false;
        }
        return !(canCreate(props.user.permissions.roadmap) || canUpdate(props.user.permissions.roadmap));
    }

    return <>
        <EntityHeader
            entity={entity}
            name={entity.attributes.Name}
            nameTitle={entity.attributes.Name}
            logo={<Logo className="roadmap-logo" />}
            leftSide={<>
                <StageView entityType={EntityType.Roadmap} value={entity.attributes.Stage} className="roadmap-stage" map={roadmapStageMap}
                    subMenuItems={stagesMenu?.filter(_ => _.data !== entity.attributes.Stage)} />
                <RoadmapSummaryWarning roadmapItems={entity.roadmapItems} />
            </>}
            buttons={<>
                <EntityHeaderMoreMenu
                    entity={entity}
                    entityType={EntityType.Roadmap}
                    items={moreMenuItems}
                    disabled={_isMoreMenuDisabled()} />
            </>}
            views={actions.layoutActions
                ? <PersistEntityViewMenu
                    layoutsState={layouts}
                    entity={entity}
                    entityType={EntityType.Roadmap}
                    readonlyMode={readonlyMode}
                    user={user}
                    viewLayout={actions.layoutActions.viewLayout}
                    saveLayout={entity.isEditable ? actions.layoutActions.saveLayout : undefined}
                    savePinnedViews={entity.canConfigure
                        ? actions.layoutActions?.updateEntityPinnedViews
                        : undefined}
                    saveEntityLayout={entity.canConfigure || readonlyMode
                        ? actions.layoutActions.updateEntityLayout
                        : undefined}
                    deleteLayout={actions.layoutActions.deleteLayout}
                    profileLayoutId={user.permissions.roadmapProfileLayoutId}
                    layoutIdByEntityId={user.permissions.layoutIdByRoadmapIdMap?.[entity.id]}
                />
                : undefined}
        ></EntityHeader>
        {
            isRemoveDialogOpen &&
            <RemoveDialog
                onClose={() => setIsRemoveDialogOpen(false)}
                onComplete={() => { actions.removeRoadmap(); }}
                dialogContentProps={{
                    title: "Delete roadmap",
                    subText: `Are you sure you want to delete roadmap "${entity.attributes.Name}" ?`
                }}
                confirmButtonProps={{ text: "Delete" }} />
        }
        {
            layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => actions.layoutActions!.applyLayout!(layoutToApply)}
                onDismiss={() => setLayoutToApply(undefined)}
                entityType={EntityType.Roadmap}
                layoutName={layoutToApply!.name}
            />
        }
    </>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        tenant: state.tenant,
        layouts: state.layouts[EntityType.Roadmap]
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps)(RoadmapHeader));