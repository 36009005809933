import * as React from 'react';
import EntityCreation from "../common/extensibleEntity/EntityCreation";
import { connect } from 'react-redux';
import { actionCreators } from "../../store/ResourcesListStore";
import { EntityType } from '../../entities/common';

interface OwnProps {
    onDismiss: () => void;
    openOnComplete: boolean;
}

type ResourceCreationProps = typeof actionCreators & OwnProps;

const ResourceCreation = (props: ResourceCreationProps) => {
    return <EntityCreation
        entityType={EntityType.Resource}
        onDismiss={props.onDismiss}
        advancedOptions={{ isCollapsible: true }}
        onSave={(name, layoutId) => props.createResource(name, layoutId, props.openOnComplete)} />;
}

export default connect(undefined, actionCreators)(ResourceCreation)