import * as React from 'react';
import { Toggle } from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { EntityProcessInfo, actionCreators } from "../../store/ProjectsListStore";
import EntityCreation from '../common/extensibleEntity/EntityCreation';
import { EntityType, entityTypeLabelMap } from '../../entities/common';
import LabellableComponent from '../common/LabellableComponent';
import { validators } from '../process/ProjectProcessField';
import SelectProcessGridItem from '../process/SelectProcessGridItem';

interface OwnProps {
    onDismiss: () => void;
    onSave?: (name: string, layoutId: string, processId: string | undefined) => void;
    openOnComplete: boolean;
    showConfigureConnectionsOption: boolean;
    isPrivateProject?: boolean;
}

type ProjectCreationProps = typeof actionCreators & OwnProps;

const ProjectCreation = (props: ProjectCreationProps) => {
    const [configureConnections, setConfigureConnections] = React.useState(props.showConfigureConnectionsOption);
    const [processInfo, setProcessInfo] = React.useState<EntityProcessInfo>();

    const onSave = (name: string, layoutId: string) => props.onSave
        ? props.onSave(name, layoutId, processInfo?.id)
        : props.createProject(name, layoutId, !!props.isPrivateProject, configureConnections, props.openOnComplete, processInfo?.id);
    
    return <EntityCreation
        entityType={EntityType.Project}
        entityTypeLabel={props.isPrivateProject ? entityTypeLabelMap[EntityType.PrivateProject].singular : undefined}
        onDismiss={props.onDismiss}
        defaultLayoutId={processInfo?.entityLayout?.id}
        advancedOptions={{
            disabled: !validators.process.isValid(processInfo),
            hideTitle: true,
            onRender: (updateIsDirty) => <>
                {props.showConfigureConnectionsOption && <div className="grid-item">
                    <LabellableComponent label='' className="field-container">
                        <Toggle className="two-column"
                            checked={configureConnections}
                            onChange={(e: any, c?: boolean) => setConfigureConnections(!!c)}
                            label="Configure Project Connections" onText='Yes' offText='No' />
                    </LabellableComponent>
                </div>}
                <SelectProcessGridItem
                    processInfo={processInfo}
                    isPrivateProject={props.isPrivateProject}
                    setProcessInfo={setProcessInfo}
                    updateIsDirty={updateIsDirty}
                />
            </>
        }}
        onSave={onSave}>
    </EntityCreation>;
}

export default connect(undefined, actionCreators)(ProjectCreation)