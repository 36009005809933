import * as React from 'react';
import { default as BulkEditComponent, IBulkEditInput } from './EntitiesBulkEdit';
import { IContextualMenuProps, IContextualMenuItem, Icon, DefaultButton, Selection } from 'office-ui-fabric-react';
import { Dictionary, IExtensibleEntity, EntityType, IEditableExtensibleEntity } from "../../entities/common";
import * as Metadata from '../../entities/Metadata';
import { getColumnIds } from "../../store/views";
import * as Notifications from "../../store/NotificationsStore";
import { DetailsSpinner } from './Spinner';
import { PngExportConfig } from './PngExporter';
import { ResultsNotFoundPlaceholder, SearchResultsNotFoundPlaceholder } from './sectionsControl/SectionPlaceholder';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EntitiesScreenHeader, { ActiveView, EntitiesScreenFilter, EntitiesScreenRouter, EntitiesScreenSearch, IEntitiesScreenView, HeaderProps } from './EntitiesScreenHeader';
import { ExtensionPageLocation, ExtensionInfo, findExtension } from '../../store/ExtensionStore';
import { ApplicationState } from '../../store';
import ExtensionPanel from './ExtensionPanel';

export interface IEntitiesScreenProps<T extends IExtensibleEntity> {
    defaultBulkEditColumns?: string[];
    getBulkEditEntities?: () => T[];
    bulkEditAttributesCustomRender?: Dictionary<IBulkEditInput>;
    fields: Metadata.Field[];
    fakeFields?: Metadata.Field[];
    readonlyFields?: ((entity: T) => string[]) | string[];
    entities: T[];
    entitiesIsUpdating?: boolean;
    activeViewType?: string;
    viewChanged: (view: IEntitiesScreenView<T>) => void;
    actions?: {
        bulkComponent?: Dictionary<any>;
        importFromFile?: (file: File) => void;
    };
    views: IEntitiesScreenView<T>[],
    filter: EntitiesScreenFilter;
    clearPreFilter?: () => void;
    selection: Selection;
    headerProps: HeaderProps;
    router: EntitiesScreenRouter;
    baseUrl: string;
    canEdit: boolean;
    canManageConfiguration: boolean;
    title: string;
    selectedItemIds: string[];
    getPngExportConfig?: () => PngExportConfig;
    search?: EntitiesScreenSearch;
}

interface IEntitiesScreenState<T> {
    active: ActiveView<T>;
    isFilterPanelOpen: boolean;
    isBulkEdit: boolean;
    bulkEditColumns: string[];
    isExtensionPanelOpen?: ExtensionInfo;
}

type ActionProps = {
    notificationsActions: typeof Notifications.actionCreators;
}

interface StateProps {
    extensions: ExtensionInfo[];
}

type Props<T extends IEditableExtensibleEntity> = ActionProps & StateProps & IEntitiesScreenProps<T>;

class EntitiesScreen<T extends IEditableExtensibleEntity> extends React.Component<Props<T>, IEntitiesScreenState<T>> {
    private BulkEditComponentT = BulkEditComponent<T>();

    constructor(props: Props<T>) {
        super(props);
        this.state = {
            active: getActiveView(props.views, props.activeViewType),
            isBulkEdit: false,
            isFilterPanelOpen: false,
            bulkEditColumns: []
        };
    }

    componentWillMount() {
        this._setActiveView(this.props);
    }

    componentWillReceiveProps(nextProps: IEntitiesScreenProps<T>) {
        this._setActiveView(nextProps);
    }

    private _setActiveView(props: IEntitiesScreenProps<T>) {
        const { type, subViewId } = props.router.match.params;

        if (type === 'bulk' && props.getBulkEditEntities) {
            const bulkEditColumns = getBulkEditColumnIds(props, this.state.active.subView);
            if (bulkEditColumns.length > 0) {
                this.setState({ isBulkEdit: true, bulkEditColumns });
            }
            else {
                console.error("No bulk edit columns");
            }
            return;
        }

        const { activeViewType } = this.props;
        const matchedView = (type
            ? props.views.find(_ => _.url === type)
            : activeViewType && props.views.find(_ => _.url === activeViewType)) || props.views[0];

        const matchedSubView = subViewId ? matchedView.subViews.find(_ => _.id === subViewId) : undefined;

        if (!matchedSubView) {
            const subView = matchedView.activeSubViewId && matchedView.subViews.find(_ => _.id === matchedView.activeSubViewId) || matchedView.subViews[0];
            subView.id && this.props.router.history.replace(`${this.props.baseUrl}/${matchedView.url}/${subView.id}`, this.props.router.location.state);
            this.setState({ isBulkEdit: false });
            return;
        }

        const { active } = this.state;
        this.setState({
            active: {
                view: matchedView,
                subView: matchedSubView
            },
            bulkEditColumns: getBulkEditColumnIds(props, matchedSubView),
            isBulkEdit: false
        });

        if (matchedView.url !== active.view.url) {
            this.props.viewChanged(matchedView);
            return;
        }

        matchedSubView.id !== active.subView.id && matchedView.onSubViewChange(matchedSubView.id);
    }

    public render() {
        const { active, isBulkEdit, bulkEditColumns, isExtensionPanelOpen } = this.state;
        const { headerProps, entitiesIsUpdating, selectedItemIds, entities } = this.props;
        return (
            <div className="entities-screen">
                {
                    !isBulkEdit && <>
                        <div className={`header ${selectedItemIds.length > 0 ? 'selection-mode' : ''}`} key="header">
                            <div className="align-center first-row" key="entities-screen-header-first-row">
                                <div className="entity-name">{this.props.title}</div>
                                <div className="sideItems">
                                    {
                                        this.props.canEdit && this.props.extensions.map((_, index) => this.renderExtensionButton(index, _.description ?? _.name, () => this.setState({ isExtensionPanelOpen: _ })))
                                    }
                                    {
                                        this.props.views.map(_ =>
                                            _.subTypes
                                                ? _.subTypes.map(__ =>
                                                    this.renderViewSwitch(__.title, __.iconName, _.url, active.view.icon === _.icon && __.isActive(), __.onClick))
                                                : this.renderViewSwitch('', _.icon, _.url, active.view.icon === _.icon))
                                    }
                                </div>
                            </div>
                            {(selectedItemIds.length === 0 || entities.length === 0) && <div className="align-center entities-screen-header-second-row">
                                <EntitiesScreenHeader
                                    baseUrl={this.props.baseUrl}
                                    headerProps={this.props.headerProps}
                                    router={this.props.router}
                                    active={this.state.active}
                                    filter={this.props.filter}
                                    search={this.props.search}
                                    entities={this.props.entities}
                                    canEdit={this.props.canEdit}
                                    canManageConfiguration={this.props.canManageConfiguration}
                                    getPngExportConfig={this.props.getPngExportConfig}
                                    importFromFile={this.props.actions?.importFromFile}
                                    fields={this.props.fields.concat(this.props.fakeFields ?? [])}
                                    isFieldFake={field => this.props.fakeFields?.indexOf(field) !== -1}
                                />
                            </div>}
                        </div>
                        <DetailsSpinner isLoading={!!entitiesIsUpdating}>
                            {this.renderActiveView()}
                        </DetailsSpinner>
                    </>
                }
                {
                    isBulkEdit &&
                    <this.BulkEditComponentT
                        entityType={headerProps.entityType}
                        fields={this.props.fields}
                        entities={this.props.getBulkEditEntities!()}
                        selectedFieldIds={bulkEditColumns}
                        readOnlyFieldNames={this._getReadOnlyFieldNames()}
                        attributesCustomRender={this.props.bulkEditAttributesCustomRender || {}}
                        save={this.save}
                        close={this.close}
                    />
                }
                {isExtensionPanelOpen && <ExtensionPanel
                    entityType={headerProps.entityType}
                    info={isExtensionPanelOpen}
                    onDismiss={() => this.setState({ isExtensionPanelOpen: undefined })}
                    context={{ entityType: headerProps.entityType }}
                />}
            </div>
        );
    }

    private _getReadOnlyFieldNames = () => this.props.fields.filter(_ => _.isReadonly).map(_ => _.name);

    private renderViewSwitch = (title: string, icon: string, url: string, isActive: boolean, onClick?: () => void) => {
        return <DefaultButton title={title} key={'viewtype_' + icon}
            onRenderIcon={() => <div className={"icon-container " + icon + (isActive ? ' on' : '')}><Icon iconName={icon} /></div>}
            className="screen-view-button"
            onClick={() => {
                onClick?.();
                this.props.router.history.push(`${this.props.baseUrl}/${url}`);
            }} />;
    }

    private renderExtensionButton = (index: number, title: string, onClick?: () => void) => {
        return <DefaultButton title={title} key={'extension_' + index}
            onRenderIcon={() => <div className={"icon-container "}><Icon iconName={"Puzzle"} /></div>}
            className="screen-view-button"
            onClick={onClick} />;
    }

    private _clearFilter = () => {
        this.props.clearPreFilter?.();
        if (this.props.headerProps.entityType !== EntityType.Project) {
            const query = new URLSearchParams(this.props.router.history.location.search);
            query.set('filter', this.props.filter.autoFilterId);
            this.props.router.history.push({ search: query.toString() })
        }
    }

    private renderActiveView = () => {
        if (this.props.entities.length === 0 && this.props.search?.search?.searchText) {
            this.props.selection?.setAllSelected(false);
            return <SearchResultsNotFoundPlaceholder />;
        }

        if (this.props.entities.length === 0 && this.props.filter.activeFilter) {
            this.props.selection?.setAllSelected(false);
            return <ResultsNotFoundPlaceholder clearFilter={this._clearFilter} />;
        }

        const { active } = this.state;
        return active.view.render('entities-screen-active-view', active.subView, this.props.entities);
    }

    private save = (updates: Dictionary<any>) => {
        if (this.props.actions && this.props.actions.bulkComponent && this.props.actions.bulkComponent.bulkUpdate) {
            this.props.actions.bulkComponent.bulkUpdate(updates);
            this.props.notificationsActions.pushNotification({ message: "Changes were saved.", type: Notifications.NotificationType.Success });
        }
    }

    private close = () => {
        this.props.router.history.push(`${this.props.baseUrl}/${this.state.active.view.url}`);
    }
}

const getActiveView = <TEntity extends IEditableExtensibleEntity>(views: IEntitiesScreenView<TEntity>[], activeViewType?: string): {
    view: IEntitiesScreenView<TEntity>,
    subView: Metadata.ISubView
} => {
    const view = activeViewType && views.find(_ => _.url === activeViewType)
    return view
        ? {
            view,
            subView: view.subViews.find(_ => _.id === view.activeSubViewId) || view.subViews[0]
        }
        : {
            view: views[0],
            subView: views[0].subViews[0]
        };
}

const getBulkEditColumnIds = <T extends IEditableExtensibleEntity>(props: IEntitiesScreenProps<T>, activeSubView?: Metadata.ISubView): string[] => {
    const { defaultBulkEditColumns, fields, views } = props;
    if (activeSubView) {
        return getActiveSubViewColumnIds(views, activeSubView);
    }
    if (!defaultBulkEditColumns) {
        return [];
    }
    return getColumnIds(defaultBulkEditColumns, fields);
}

export const getActiveSubViewColumnIds = (views: IEntitiesScreenView<IEditableExtensibleEntity>[], activeSubView: Metadata.ISubView): string[] => {
    const viewType = views.find(_ => _.subViews.find(__ => __.id === activeSubView.id))?.url;
    if (viewType?.toLowerCase() === Metadata.ViewTypes.list) {
        return (activeSubView as Metadata.IListSubView).columns.map(_ => _.id)
    }

    if (viewType?.toLowerCase() === Metadata.ViewTypes.timeline) {
        return (activeSubView as Metadata.ITimelineSubView).columns.map(_ => _.id)
    }

    return [];
}

export const getActiveViewType = <TEntity extends IEditableExtensibleEntity>(
    views: IEntitiesScreenView<TEntity>[],
    activeViewType?: string
): Metadata.ViewTypes | undefined => {
    const activeViewUrl = getActiveView(views, activeViewType).view.url;
    return activeViewUrl ? Metadata.ViewTypes[activeViewUrl] : undefined;
}

export default function <T extends IEditableExtensibleEntity>() {
    function mapStateToProps(state: ApplicationState, ownProps: IEntitiesScreenProps<T>): StateProps {
        return {
            extensions: findExtension(state.extensions, ownProps.headerProps.entityType, ExtensionPageLocation.Views)
        };
    }

    function mergeActionCreators(dispatch: any): ActionProps {
        return {
            notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch)
        }
    }

    return connect(mapStateToProps, mergeActionCreators)(EntitiesScreen as new (props: Props<T>) => EntitiesScreen<T>);
}
