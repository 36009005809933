import * as React from "react";
import { Icon, IStyle } from "office-ui-fabric-react";

type Props = {
    color: string;
    percent: number;
    iconName: string;
    classNames?: {
        root?: string;
        icon?: string;
    };
    getIconRootStyles?: (shadeColor: string) => IStyle;
};

const ShadableEntityIcon = (props: React.PropsWithChildren<Props>) => {
    const { color, iconName, percent, classNames, getIconRootStyles } = props;
    const shadeColor = calculateShadeColor(color, percent)!;

    return (
        <div className={`logo align-center ${classNames?.root ?? ''}`} style={{ backgroundColor: shadeColor }}>
            <Icon
                iconName={iconName}
                className={`icon ${classNames?.icon ?? ''}`}
                styles={{ root: getIconRootStyles ? getIconRootStyles(shadeColor) : { color } }}
            />
            {props.children}
        </div>
    );
};

const FULL_HEX = 0x1000000;
const RED_SHIFT = 16;
const GREEN_SHIFT = 8;
const CHANNEL_MASK = 0x00ff;
const MAX_COLOR_VALUE = 255;
const MIN_COLOR_VALUE = 0;

export function calculateShadeColor(color: string | undefined, percent: number, withAlpha?: boolean) {
    if (color === undefined) {
        return undefined;
    }

    const f = parseInt(color.slice(1), 16);
    const t = percent < 0 ? MIN_COLOR_VALUE : MAX_COLOR_VALUE;
    const p = Math.abs(percent);
    const R = f >> RED_SHIFT;
    const G = (f >> GREEN_SHIFT) & CHANNEL_MASK;
    const B = f & CHANNEL_MASK;

    if (withAlpha) {
        return `rgba(${R}, ${G}, ${B}, ${percent})`;
    }

    return (
        "#" +
        (FULL_HEX +
            (Math.round((t - R) * p) + R) * 0x10000 +
            (Math.round((t - G) * p) + G) * 0x100 +
            (Math.round((t - B) * p) + B))
            .toString(16)
            .slice(1)
    );
}

export default ShadableEntityIcon;
