import * as React from 'react';
import { IconButton, IContextualMenuItem } from 'office-ui-fabric-react';
import EntityHeader from '../../common/EntityHeader';
import RemoveDialog from '../../common/RemoveDialog';
import Logo from '../../common/Logo';
import StageView from '../../views/list/columns/StageView';
import { canBeDeleted, IProcessAttrs, Process, ProcessStatus } from '../../../store/process/common';
import { ProcessStatusMap } from '../../../store/ProcessesListStore';
import { PPMFeatures, Subscription } from '../../../store/Tenant';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Field, getOptions } from '../../../entities/Metadata';
import { EntityType } from '../../../entities/common';
import { nameof } from '../../../store/services/metadataService';
import { notUndefined } from '../../utils/common';

interface IActions {
    cloneProcess: () => void;
    removeProcess: () => void;
    updateProcessStatus: (status: ProcessStatus) => void;
    makeProcessDefault: () => void;
}

type OwnProps = {
    entity: Process;
    actions: IActions;
}

type StateProps = {
    subscription: Subscription;
    fields: Field[];
}

type Props = OwnProps & StateProps;

const ProcessHeader = (props: Props) => {
    const { entity, actions, subscription, fields } = props;
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
    const canCreateProcesses = Subscription.contains(subscription, PPMFeatures.ProcessCreation);
    
    const statusField = React.useMemo(() => {
        const field = fields.find(_ => _.name === nameof<IProcessAttrs>('Status'));
        if (!field) {
            return undefined;
        }

        const updatedField = removeDraftOptionIfEntitiesExist(field, entity);
        delete updatedField.settings.options[entity.attributes.Status];
        
        return updatedField;
    }, [fields, entity]);

    const statuses = React.useMemo(() => statusField && entity.isEditable && !entity.isDefault
        ? getOptions(statusField).map(_ => {
            const status = Number(_.key);
            return {
                key: status.toString(),
                data: status,
                name: _.text,
                onClick: () => actions.updateProcessStatus(status)
            };
        }) : [], 
    [statusField, actions.updateProcessStatus]);

    const moreMenuItems: IContextualMenuItem[] = [
        buildSetProcessAsDefaultAction(entity, actions.makeProcessDefault),
        {
            key: 'clone-process',
            iconProps: { iconName: 'Copy' },
            name: 'Clone Process',
            title: !canCreateProcesses ? 'Upgrade your subscription to create new processes' : undefined,
            disabled: !entity.isEditable || !canCreateProcesses,
            onClick: () => actions.cloneProcess()
        },
        {
            key: 'delete-process',
            iconProps: { iconName: 'Delete' },
            name: 'Delete Process',
            className: 'more-deleteButton',
            disabled: !canBeDeleted(entity),
            title: getDeleteProcessTitle(entity),
            onClick: () => setIsRemoveDialogOpen(true)
        }
    ].filter(notUndefined);
    
    return <>
        <EntityHeader
            entity={entity}
            name={entity.attributes.Name}
            nameTitle={entity.attributes.Name}
            logo={<Logo className="process-logo" />}
            leftSide={(
                <>
                    {entity.isDefault && <span className="entity-indicator default-process-indicator">(Default)</span>}
                    <StageView
                        entityType={EntityType.Process}
                        value={entity.attributes.Status}
                        className="process-status"
                        map={ProcessStatusMap}
                        subMenuItems={statuses}
                    />
                </>
            )}
            buttons={<IconButton
                menuIconProps={{ iconName: 'More' }}
                className='menu'
                text='More'
                disabled={!entity.isEditable}
                menuProps={{ items: moreMenuItems, className: 'section-menu' }}
            />}
        />
        {
            isRemoveDialogOpen && (
                <RemoveDialog
                    onClose={() => setIsRemoveDialogOpen(false)}
                    onComplete={() => { actions.removeProcess(); }}
                    dialogContentProps={{
                        title: "Delete process",
                        subText: `Are you sure you want to delete process "${entity.attributes.Name}" ?`
                    }}
                    confirmButtonProps={{ text: "Delete" }}
                />
            )
        }
    </>;
}

export const buildSetProcessAsDefaultAction = (process: Process, makeProcessDefault: (id: string) => void) =>
    !process.isDefault && process.isEditable
        ? {
              key: "set-as-default",
              name: "Set as Default",
              disabled: process.attributes.Status !== ProcessStatus.Active,
              title: process.attributes.Status !== ProcessStatus.Active
                ? "Process is not active"
                : "Process marked as Default will be pre-selected in the Project Process field during Project creation",
              iconProps: { iconName: "ActivateOrders" },
              onClick: () => makeProcessDefault(process.id),
          }
        : undefined;

const getDeleteProcessTitle = (process: Process) => {
    if (canBeDeleted(process)) {
        return undefined;
    }

    const isDefault = process.isDefault;
    const isInUse = process.entitiesCount > 0;

    let reason = 'Process cannot be deleted, because it is';
    if (isDefault) {
        reason += ' set as default';
    }
    if (isDefault && isInUse) {
        reason += ' and';
    }
    if (isInUse) {
        reason += ' already in use in projects';
    }
    reason += '.';

    return reason;
};

export function removeDraftOptionIfEntitiesExist(statusField: Field, process: Process) {
    const fieldCopy = { ...statusField, settings: { ...statusField.settings, options: { ...statusField.settings!.options } } };
    if (process.entitiesCount > 0) {
        delete fieldCopy.settings.options[ProcessStatus.Draft];
    }
    return fieldCopy;
}

function mapStateToProps(state: ApplicationState): StateProps {
    const fields = state.fields[EntityType.Process];
    return {
        subscription: state.tenant.subscription,
        fields: fields.allIds.map(_ => fields.byId[_]),
    };
}

export default connect(mapStateToProps)(ProcessHeader);
