import * as React from 'react';
import { connect } from "react-redux";
import { Field } from "../../../../../entities/Metadata";
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { ProjectInfo, actionCreators } from "../../../../../store/ProjectsListStore";
import FavoriteToggle from '../../../../common/FavoriteToggle';
import { isInReadonlyMode } from '../../../../../store/User';
import { ApplicationState } from '../../../../../store';

type OwnProps = IListViewFieldColumn<ProjectInfo>;
type StateProps = {
    isReadonlyMode: boolean;
};
type ActionProps = typeof actionCreators;

type Props = OwnProps & StateProps & ActionProps;

export const Favorite = (props: Props) => {
    const onFavoriteChange = (isFavorite: boolean) => {
        props.setFavorite(props.entity.id, isFavorite);
    }

    return (
        <FavoriteToggle
            isFavorite={props.entity.isFavorite}
            onChange={onFavoriteChange}
            className="clickable favorite"
            disabled={props.isReadonlyMode}
        />
    );
}

export const getValue = (entity: ProjectInfo, field: Field): boolean | undefined => entity.isFavorite;
export const getSearchValue = (entity: ProjectInfo, field: Field): string | undefined => entity.isFavorite ? "Yes" : "No";

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant)
    };
}

export default connect(mapStateToProps, actionCreators)(Favorite);