import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { bindActionCreators } from 'redux';
import { Dictionary, EntityType, IPatch, PhaseCategory } from "../../../entities/common";
import { DetailsSpinner } from "../../common/Spinner";
import * as ProcessStagesControl from '../../common/sectionsControl/uiControls/ProcessStagesControl';
import { actionCreators as ProcessesActionCreators } from '../../../store/ProcessesListStore';
import { IProcessAttrs, Process, ProcessStatus, StagePhase } from '../../../store/process/common';
import { nameof, namesof } from '../../../store/services/metadataService';
import { IProcessStage } from '../../../entities/Subentities';
import { ICreationData } from '../../../store/Subentity';
import { IControlConfiguration } from '../../common/interfaces/ISectionUIControlProps';
import { IFieldsAreaConfiguration } from '../../common/sectionsControl/uiControls/fieldsArea/common';
import ProcessHeader, { removeDraftOptionIfEntitiesExist } from './ProcessHeader';
import ProcessSectionsContainer from './ProcessSectionsContainer';
import { buildViewTypeSelect, ViewTypeViews } from '../../common/ViewTypeSelect';
import * as Metadata from '../../../entities/Metadata';
import { IInputProps } from '../../common/interfaces/IInputProps';
import { DisplayFieldService } from '../../common/DisplayFieldService';

interface IConfiguration extends Dictionary<IControlConfiguration> {
    FieldsArea: IFieldsAreaConfiguration;
    ProcessStagesControl: ProcessStagesControl.IConfiguration;
}

type StateProps = {
    entity?: Process;
    isLoading: boolean;
};

type ActionProps = {
    processesActions: typeof ProcessesActionCreators;
};

type Props = RouteComponentProps<{ id: string }> & StateProps & ActionProps;

const ProcessDetails = (props: Props) => {
    const { entity } = props;
    
    React.useEffect(() => {
        props.processesActions.loadProcess(props.match.params.id);
    }, [props.match.params.id]);

    const cloneProcess = () => {
        props.processesActions.cloneProcess(entity!.id);
    };

    const removeProcess = () => {
        props.processesActions.removeProcesses([entity!.id], true);
    };

    const updateProcessStatus = (status: ProcessStatus) => {
        props.processesActions.updateProcessAttributes(entity!.id, { [nameof<IProcessAttrs>('Status')]: status });
    };

    const makeProcessDefault = () => {
        props.processesActions.makeOpenedProcessDefault(entity!.id);
    };

    const refreshEntity = () => {
        props.processesActions.loadProcess(entity!.id);
    };

    const onEditComplete = (fieldName: string, fieldValue: any, extra: Dictionary<any>): void => {
        if (!fieldName) {
            return;
        }
        props.processesActions.updateProcessAttributes(entity!.id, { [fieldName]: fieldValue, ...extra });
    };

    const createProcessStage = (processStage: ICreationData) => {
        props.processesActions.createProcessStages(props.entity!.id, [processStage]);
    };
    
    const createProcessStages = (processStages: ICreationData[]) => {
        props.processesActions.createProcessStages(props.entity!.id, processStages);
    };

    const updateProcessStage = (processStageId: string, changes: Dictionary<unknown>) => {
        props.processesActions.updateProcessStages(props.entity!.id, [{ id: processStageId, attributes: changes }]);
    };

    const updateProcessStages = (processStages: IPatch<IProcessStage>[]) => {
        props.processesActions.updateProcessStages(props.entity!.id, processStages);
    };

    const removeProcessStages = (processStageIds: string[]) => {
        props.processesActions.removeProcessStages(props.entity!.id, processStageIds);
    };

    const updateStagePhases = (stagePhases: StagePhase[]) => {
        props.processesActions.updateProcessStagePhases(props.entity!.id, stagePhases);
    };

    const dragProcessStages = (entities: string[], groupId?: string, insertbeforeId?: string) => {
        if (!groupId) {
            return;
        }
        const category = Number(groupId) as PhaseCategory;
        props.processesActions.dragProcessStagesToCategory(props.entity!.id, entities, category, insertbeforeId);
    };

    const disabledReadOnlyFields = React.useMemo(() => {
        const fields = namesof<IProcessAttrs>(['EntityType']);
        if (entity?.isDefault) {
            fields.push(nameof<IProcessAttrs>('Status'));
        }
        return fields;
    }, [entity]);

    const buildControlsConfigurations = (): IConfiguration => {
        return {
            ['FieldsArea']: {
                datacontext: {
                    entityId: entity!.id,
                    entityType: EntityType.Process,
                    readOnlyFields: disabledReadOnlyFields,
                    disabledFields: disabledReadOnlyFields,
                },
                actions: {
                    refreshEntity,
                    onEditComplete,
                    updateUIControl: null,
                },
                editProps: {
                    elementCustomRender: {
                        [nameof<IProcessAttrs>("Status")]: (inputProps: IInputProps, state: Process, field: Metadata.Field): JSX.Element | null => {
                            const updatedField = removeDraftOptionIfEntitiesExist(field, state);
                            return DisplayFieldService.buildFieldInputElement(updatedField, inputProps);
                        }
                    }
                },
            },
            ['ProcessStagesControl']: {
                ...buildViewTypeSelect(ViewTypeViews.ProcessStagesControl),
                actions: {
                    create: createProcessStage,
                    update: updateProcessStage,
                    createBulk: createProcessStages,
                    updateBulk: updateProcessStages,
                    remove: removeProcessStages,
                    updateUIControl: null,
                    refreshEntity,
                    updateStagePhases: updateStagePhases,
                    dragEntities: dragProcessStages,
                }
            },
        };
    }

    return (
        <DetailsSpinner isLoading={props.isLoading}>
            {entity && <ProcessHeader
                entity={entity}
                actions={{ cloneProcess, removeProcess, updateProcessStatus, makeProcessDefault }}
            />}
            {entity && (
                <ProcessSectionsContainer
                    process={entity}
                    controlsConfig={buildControlsConfigurations()}
                />
            )}
        </DetailsSpinner>
    );
};

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        processesActions: bindActionCreators(ProcessesActionCreators, dispatch),
    };
}

function mapStateToProps(state: ApplicationState, ownProps: RouteComponentProps<{ id: string }>): StateProps {
    return {
        entity: state.processes.activeEntity && state.processes.activeEntity.id === ownProps.match.params.id ? state.processes.activeEntity : undefined,
        isLoading: state.processes.isLoading,
    };
}

export default connect(mapStateToProps, mergeActionCreators)(ProcessDetails);
