import * as React from 'react';
import { get } from '../../../fetch-interceptor';
import { Autofill } from 'office-ui-fabric-react';
import { IFormInputProps } from '../../common/interfaces/IFormInputProps';
import OptionsPicker, { Option } from './OptionsPicker';

type StageOption = Option & { isNew?: boolean; };

type Props = IFormInputProps<string[], Autofill>;

const ProcessStagePicker = (props: Props) => {
    const [value, setValue] = React.useState<string[] | undefined>(props.value);
    const [stages, setStages] = React.useState<StageOption[]>();
    const [stagesPromise, setStagesPromise] = React.useState<Promise<StageOption[]>>();

    const selectedItems: StageOption[] = value?.map(_ => ({ key: _, text: _ })) ?? [];
    
    const onRenderSuggestionsItem = (option: StageOption) => (
        <div className="picker-suggestion-item">{option.text}</div>
    );

    const onResolveSuggestions = (filter: string, selectedOptions?: StageOption[]) => {
        let array = stages
            ? Promise.resolve(stages)
            : stagesPromise;

        if (!array) {
            const stageOptionsPromise = get<string[]>("api/process/stages/find")
                .then(stageNames => {
                    const stageOptions = stageNames.map(_ => ({ key: _, text: _ }) as StageOption);
                    setStages(stageOptions);
                    return stageOptions;
                });
    
            setStagesPromise(stageOptionsPromise);
            array = stageOptionsPromise;
        }

        const normalizedFilter = filter.trim().toLowerCase();
        if (filter) {
            array = array.then(_ => _.filter(__ => __.text.toLowerCase().startsWith(normalizedFilter)));
            array = array.then(_ => _.findIndex(__ => __.text.toLowerCase() === normalizedFilter) === -1 ? [{ text: filter, isNew: true } as StageOption].concat(_) : _);
        }

        if (selectedOptions?.length) {
            const isSelected = (option: StageOption) => selectedOptions.some(_ => _.text === option.text);
            const filterSelectedItems = (options: StageOption[]) => options.filter(_ => !isSelected(_));
            array = array.then(_ => filterSelectedItems(_));
        }

        return array;
    };
    
    const onChange = (items?: StageOption[]) => {
        const item = items?.length && items[items.length - 1];
        
        if (item && item.isNew) {
            const newStages = [...(stages ?? []), item];
            const uniqueNewStages = getUniqueStages(newStages);
            setStages(uniqueNewStages);
        }

        const newValue = items?.map(_ => _.text) ?? undefined;
        setValue(newValue);

        props.onEditComplete?.(newValue ?? null);
    };

    return (
        <OptionsPicker
            {...props}
            selectedItems={selectedItems}
            onChange={onChange}
            onRenderSuggestionsItem={onRenderSuggestionsItem}
            onResolveSuggestions={onResolveSuggestions}
            pickerSuggestionsProps={{ noResultsFoundText: "No stages found", loadingText: 'Loading' }}
        />
    );
}

const getUniqueStages = (stages: StageOption[]) => {
    const uniqueStages = stages.filter((stage, index, self) =>
        index === self.findIndex((s) => s.text.toLowerCase() === stage.text.toLowerCase())
    );
    return uniqueStages.sort((a, b) => a.text > b.text ? 1 : -1);
};

export default ProcessStagePicker;