import React, { useState } from 'react';
import "./ColorCategorySettingsEdit.css";
import { CategoryConfig, CategoryOption, Dictionary } from '../../../../entities/common';
import { DraggableOption } from '../../SelectSettingsEdit/OptionEdit';
import RemoveDialog from '../../../common/RemoveDialog';
import CategoryColorOptionEdit from './CategoryColorOptionEdit';
import CategoryHeader from "./CategoryHeader";
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import GroupedDraggableList, { GroupedDraggableItems } from '../../../common/GroupedDraggableList';
import EmptyGroupMessage from '../EmptyGroupMessage';
import { areOptionsUnique, areOptionsValid } from '../../SelectSettingsEdit/SelectSettingsEdit';

type Props = {
    categoryConfig: Dictionary<CategoryConfig>;
    categoryTypeName: string;
    defaultInCategoryRequired: boolean;
    getDeletionWarning: (category: CategoryOption<any>) => JSX.Element;
    orderedCategories: any[];
    options: DraggableOption<CategoryOption<any>>[];
    isUnique: () => boolean;
    onChange: (options: DraggableOption<CategoryOption<any>>[]) => void;
};

const ColorCategorySettingsEdit = (props: Props) => {
    const { categoryConfig, categoryTypeName, options, orderedCategories, isUnique, onChange, getDeletionWarning, defaultInCategoryRequired } = props;
    const [editedOption, setEditedOption] = useState<DraggableOption<CategoryOption<any>> | undefined>(undefined);
    const [optionToDelete, setOptionToDelete] = useState<DraggableOption<CategoryOption<any>> | undefined>(undefined);
    const [draggableOptions, setDraggableOptions] = useState(options);

    const groupedItemsMap = React.useMemo(() => orderedCategories.map(_ => ({ group: "group_" + _, items: draggableOptions.filter(__ => __.category === _) })), [orderedCategories, draggableOptions]);
    const groupNameToCategoryMap = orderedCategories.reduce((acc, _) => ({ ...acc, ["group_" + _]: _ }), {});

    const isNewOption = (option: DraggableOption<CategoryOption<any>>) => option.id.startsWith('new_');

    const onAddOption = (category: any) => {
        const newOption: DraggableOption<CategoryOption<any>> = {
            id: 'new_' + Date.now(),
            name: '',
            color: categoryConfig[category].color,
            category: category
        };

        const beforeCategories = orderedCategories.slice(0, orderedCategories.indexOf(category) + 1);
        const categoryOptionsIndex = draggableOptions.filter(_ => beforeCategories.includes(_.category)).length;

        const newOptions = [...draggableOptions].filter(_ => !!_.name.trim());
        newOptions.splice(categoryOptionsIndex, 0, newOption);
        
        setDraggableOptions(newOptions);
        setEditedOption(newOption);
        onChange(newOptions);
    };

    const onOptionChange = (option: DraggableOption<CategoryOption<any>>) => {
        const newOptions = draggableOptions.map(_ => _.id === option.id ? option : _);
        setDraggableOptions(newOptions);
        onChange(newOptions);
    };

    const onCancel = (initialOption: DraggableOption<CategoryOption<any>>) => {
        setEditedOption(undefined);
        if (isNewOption(initialOption) && !initialOption.name) {
            deleteOption(initialOption);
        } else {
            onOptionChange(initialOption);
        }
    };

    const deleteOption = (option: DraggableOption<CategoryOption<any>> | undefined) => {
        if (!option) {
            return;
        }

        const newOptions = draggableOptions.filter(_ => _.id !== option.id)
        setDraggableOptions(newOptions);
        onChange(newOptions);
    };

    const onEmptyGroupItemsRender = React.useCallback((group: string) => <EmptyGroupMessage message={`No ${categoryTypeName.toLowerCase()}s have been listed in this category.`} />, []);

    const onOrderChanged = React.useCallback((changedGroupedItems: GroupedDraggableItems<DraggableOption<CategoryOption<any>>>[], destinationGroup: string, option: DraggableOption<CategoryOption<any>>) => {
        if (option.color?.toLowerCase() === categoryConfig[option.category].color?.toLowerCase()) {
            option.color = categoryConfig[groupNameToCategoryMap[destinationGroup]].color;
        }

        let newOpts = [...draggableOptions];
        for (const group of changedGroupedItems) {
            const category = groupNameToCategoryMap[group.group];
            group.items.forEach(_ => _.category = category);
            newOpts = newOpts.filter(_ => _.category !== category);

            const beforeCategories = orderedCategories.slice(0, orderedCategories.indexOf(category));
            const categoryOptionsIndex = newOpts.filter(_ => beforeCategories.includes(_.category)).length;

            newOpts.splice(categoryOptionsIndex, 0, ...group.items);
        }
        setDraggableOptions(newOpts);
        onChange(newOpts);
    }, [draggableOptions, onChange]);

    return (
        <div className='color-category-settings-edit'>
            <div className="options">
                <GroupedDraggableList
                    groupedItems={groupedItemsMap}
                    onEmptyGroupItemsRender={onEmptyGroupItemsRender}
                    onGroupRender={(groupedItems: GroupedDraggableItems<DraggableOption<CategoryOption<any>>>, onGroupedItemsRender: () => JSX.Element | JSX.Element[]) =>
                        <div key={groupedItems.group} className='color-category-settings-edit'>
                            <CategoryHeader {...categoryConfig[groupNameToCategoryMap[groupedItems.group]]} typeName={categoryTypeName} onAdd={() => onAddOption(groupNameToCategoryMap[groupedItems.group])} />
                            <div className="options">
                                {onGroupedItemsRender()}
                            </div>
                        </div>}
                    onItemRender={(item: DraggableOption<CategoryOption<any>>, group: string, index) => (
                        <CategoryColorOptionEdit
                            option={item}
                            index={index}
                            defaultRequired={defaultInCategoryRequired}
                            categoryIconName={categoryConfig[groupNameToCategoryMap[group]].iconName}
                            isUnique={isUnique}
                            isInEditMode={editedOption?.id === item.id}
                            onEnterEditMode={() => setEditedOption(item)}
                            onChange={onOptionChange}
                            onEditComplete={() => setEditedOption(undefined)}
                            onCancel={onCancel}
                            onRemove={draggableOptions.length > 1 && (!defaultInCategoryRequired || draggableOptions.filter(_ => _.category === groupNameToCategoryMap[group] && !!_.name.trim()).length > 1)
                                ? () => setOptionToDelete(item)
                                : undefined}
                        />
                    )}
                    isItemDraggable={(_, group) => !defaultInCategoryRequired || draggableOptions.filter(_ => _.category === groupNameToCategoryMap[group]).length > 1}
                    onChanged={onOrderChanged}
                />
            </div>
            {optionToDelete && <DeletionDialog
                name={optionToDelete.name}
                typeName={categoryTypeName}
                onConfirm={() => deleteOption(optionToDelete)}
                onClose={() => setOptionToDelete(undefined)}
                warningMessage={getDeletionWarning(optionToDelete)}
            />}
        </div>
    );
}

export default ColorCategorySettingsEdit;

export function isEqual<T>(option1: CategoryOption<T>, option2: CategoryOption<T>) {
    return option1.name === option2.name && option1.color === option2.color && option1.category === option2.category;
}

function optionsSetSelector<T>(option: DraggableOption<CategoryOption<T>>) {
    return option.name.toLowerCase();
}

export function areCategoryOptionsValid<T>(options: DraggableOption<CategoryOption<T>>[]) {
    return areOptionsValid(options, optionsSetSelector);
}

export function areCategoryOptionsUnique<T>(options: DraggableOption<CategoryOption<T>>[]) {
    return areOptionsUnique(options, optionsSetSelector);
}

type DeletionDialogProps = {
    name: string;
    typeName: string;
    warningMessage?: JSX.Element;
    onConfirm: () => void;
    onClose: () => void;
};

const DeletionDialog = ({ name, typeName, warningMessage, onConfirm, onClose }: DeletionDialogProps) => (
    <RemoveDialog
        dialogContentProps={{
            title: `Delete ${typeName}`,
            subText: `Are you sure you want to delete the ${typeName.toLowerCase()} '${name}'?`,
        }}
        confirmButtonProps={{
            text: "Confirm",
        }}
        onComplete={onConfirm}
        onClose={onClose}
    >
        {warningMessage && <MessageBar messageBarType={MessageBarType.warning}>
            {warningMessage}
        </MessageBar>}
    </RemoveDialog>
);
