import * as React from 'react';
import * as analytics from '../../analytics';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Metadata from "../../entities/Metadata";
import { UserState, isInReadonlyMode } from '../../store/User';
import { canCreate, canUpdate } from '../../store/permissions';
import { IconButton } from 'office-ui-fabric-react';
import { ILayoutActions, LayoutService } from '../utils/LayoutService';
import { EntityType,  ISourceInfo } from '../../entities/common';
import { TenantState } from '../../store/Tenant';
import { notUndefined } from '../utils/common';
import { LayoutsState } from '../../store/layouts';
import PersistEntityViewMenu from '../common/PersistEntityViewMenu';
import EntityHeader from '../common/EntityHeader';
import RemoveDialog from '../common/RemoveDialog';
import Logo from '../common/Logo';
import { Challenge, challengeStagesMap } from '../../store/ChallengesListStore';
import StageView from '../views/list/columns/StageView';
import { SourceType, SourceType_ } from '../../store/ExternalEpmConnectStore';
import ApplyLayoutConfirmationDialog from '../common/ApplyLayoutConfirmationDialog';
import EntityHeaderMoreMenu from '../common/EntityHeaderMoreMenu';

interface IActions {
    updateImage?: (logo: File) => void;
    removeImage?: () => void;
    layoutActions?: ILayoutActions;
    removeChallenge: () => void;
    openConfigurationPanel: () => void;
    changeDefaultIdeaLayout: (layout: Metadata.Layout) => void;
}

type OwnProps = {
    entity: Challenge;
    actions: IActions;
}

type StateProps = {
    user: UserState;
    tenant: TenantState;
    layouts: LayoutsState;
    ideaLayouts: LayoutsState;
}
type Props = OwnProps & StateProps & RouteComponentProps<{}>;

const ChallengeHeader = (props: Props) => {
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
    const [layoutToApply, setLayoutToApply] = React.useState<Metadata.Layout>();

    const { entity, actions, layouts, user, tenant, ideaLayouts } = props;

    const moreMenuItems = [
        entity.canConfigure && actions.layoutActions?.applyLayout ? {
            ...LayoutService.buildApplyLayoutMenuItem(layouts, (layout) => setLayoutToApply(layout), entity.layoutId),
            disabled: !entity.isEditable
        } : undefined,
        entity.canConfigure ? {
            ...LayoutService.buildApplyLayoutMenuItem(ideaLayouts, actions.changeDefaultIdeaLayout, entity.defaultIdeaLayoutId),
            key: "idea-layout",
            text: "Set Idea Layout for this Challenge",
            iconProps: { iconName: 'FormLibrary' },
            disabled: !entity.isEditable
        } : undefined,
        {
            key: 'deleteChallenge',
            iconProps: { iconName: 'Delete' },
            name: 'Delete Challenge',
            className: 'more-deleteButton',
            disabled: !entity.isEditable,
            onClick: () => setIsRemoveDialogOpen(true)
        }
    ].filter(notUndefined);
    const readonlyMode = isInReadonlyMode(user, tenant);
    const isConnectPanelAvailable = entity.isEditable || entity.sourceInfos.length && readonlyMode;

    const _isMoreMenuDisabled = () => {
        if (!props.entity.isEditable) {
            return false;
        }
        return !(canCreate(props.user.permissions.challenge) || canUpdate(props.user.permissions.challenge));
    }

    const renderTeamsButton = (sourceInfo: ISourceInfo) => {
        return [
            sourceInfo.sourceData.channelUrl &&
            <span key="link-to-teams" className="linked-system">
                <IconButton
                    iconProps={{ iconName: SourceType_.getIconName(sourceInfo.type) }}
                    title="MS Teams"
                    href={sourceInfo.sourceData.channelUrl}
                    target="_blank"
                    disabled={!sourceInfo.sourceData.channelUrl} />
            </span>
        ];
    };

    return <>
        <EntityHeader
            entity={entity}
            name={entity.attributes.Name}
            nameTitle={entity.attributes.Name}
            logo={<Logo className="challenge-logo"
                imageId={entity.imageId}
                onChanged={!entity.isEditable && actions.updateImage ? undefined : (image: File) => actions.updateImage!(image)}
                onRemove={!entity.isEditable ? undefined : actions.removeImage} />}
            leftSide={<StageView
                entityType={EntityType.Challenge}
                value={entity.attributes.Stage}
                className="challenge-stage"
                map={challengeStagesMap}
            />}
            buttons={<>
                {
                    entity.sourceInfos.map(sourceInfo => {
                        if (sourceInfo.type === SourceType.O365TeamsChannel) {
                            return renderTeamsButton(sourceInfo);
                        }
                        return undefined;
                    })
                }
                <IconButton iconProps={{ iconName: 'PPMXLink' }}
                    className="connect-launcher"
                    title="Connect"
                    disabled={!isConnectPanelAvailable}
                    onClick={() => { actions.openConfigurationPanel(); }} />
                <EntityHeaderMoreMenu 
                    entity={entity}
                    entityType={EntityType.Challenge}
                    items={moreMenuItems}
                    disabled={_isMoreMenuDisabled()} />
            </>}
            views={actions.layoutActions
                ? <PersistEntityViewMenu
                    layoutsState={layouts}
                    entity={entity}
                    entityType={EntityType.Challenge}
                    readonlyMode={readonlyMode}
                    user={user}
                    viewLayout={actions.layoutActions?.viewLayout}
                    saveLayout={entity.isEditable ? actions.layoutActions?.saveLayout : undefined}
                    savePinnedViews={entity.canConfigure
                        ? actions.layoutActions?.updateEntityPinnedViews
                        : undefined}
                    saveEntityLayout={entity.canConfigure || readonlyMode
                        ? actions.layoutActions?.updateEntityLayout
                        : undefined}
                    deleteLayout={actions.layoutActions?.deleteLayout}
                    profileLayoutId={user.permissions.challengeProfileLayoutId}
                    layoutIdByEntityId={user.permissions.layoutIdByChallengeIdMap?.[entity.id]}
                />
                : undefined}
        ></EntityHeader>
        {
            isRemoveDialogOpen &&
            <RemoveDialog
                onClose={() => setIsRemoveDialogOpen(false)}
                onComplete={() => { actions.removeChallenge(); }}
                dialogContentProps={{
                    title: "Delete challenge",
                    subText: `Are you sure you want to delete challenge "${entity!.attributes.Name}" ?`
                }}
                confirmButtonProps={{ text: "Delete" }} />
        }
        {
            layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => actions.layoutActions!.applyLayout!(layoutToApply)}
                onDismiss={() => setLayoutToApply(undefined)}
                entityType={EntityType.Challenge}
                layoutName={layoutToApply!.name}
            />
        }
    </>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        tenant: state.tenant,
        layouts: state.layouts[EntityType.Challenge],
        ideaLayouts: state.layouts[EntityType.Idea],
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps)(ChallengeHeader));