import { EntityType, entityTypeLabelMap } from "../../entities/common";
import ProjectProcessField, { ProjectProcessFieldProps } from "./ProjectProcessField";

type Props = ProjectProcessFieldProps & {
    isPrivateProject?: boolean;
};

const SelectProcessGridItem = (props: Props) => {
    const { processInfo, isPrivateProject, setProcessInfo, updateIsDirty } = props;

    const getEntityLabel = () => {
        const entityType = isPrivateProject ? EntityType.PrivateProject : EntityType.Project;
        return entityTypeLabelMap[entityType].singular;
    };

    return (
        <div className="grid-item">
            <span>Select a process and a layout that will be applied to this {getEntityLabel()}</span>
            <ProjectProcessField processInfo={processInfo} setProcessInfo={setProcessInfo} updateIsDirty={updateIsDirty} />
        </div>
    );
};

export default SelectProcessGridItem;
