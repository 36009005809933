import * as React from 'react';
import { bindActionCreators } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Metadata from "../../entities/Metadata";
import { UserState, isInReadonlyMode } from '../../store/User';
import { contains, canCreate, CommonOperations } from '../../store/permissions';
import { ContextualMenuItemType, IconButton, IContextualMenuItem } from 'office-ui-fabric-react';
import CategoryColorStatusView from '../views/list/columns/CategoryColorStatusView';
import { nameof } from '../../store/services/metadataService';
import { LayoutService } from '../utils/LayoutService';
import { EntityType, StatusCategory, Dictionary } from '../../entities/common';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';
import EntityHeader from '../common/EntityHeader';
import Logo from '../common/Logo';
import { IObjectiveAttrs, OKRState, Objective, actionCreators, objectiveStatesMap } from '../../store/ObjectivesListStore';
import * as ObjectivesList from './ObjectivesList';
import { OKRStateChangeAction, OKRStateChangeProps, ObjectiveStateDialogs, ObjectivesToRemoveRemoveDialog } from './OKRDialogs';
import StageView from '../views/list/columns/StageView';
import { EditLayoutPanel, EditType } from '../common/EditLayoutPanel';

type OwnProps = {
    entity: Objective;
}

type StateProps = {
    user: UserState;
    sections: Metadata.Section[];
    fields: Metadata.Field[];
    hasSubObjectives: boolean;
    isReadonlyMode: boolean;
}
type ActionProps = {
    objectivesActions: typeof actionCreators;
};
type Props = OwnProps & StateProps & ActionProps & RouteComponentProps<{}>;

const ObjectiveHeader = (props: Props) => {
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
    const [isLayoutPanelOpen, setIsLayoutPanelOpen] = React.useState(false);
    const [obectiveStateChangeProps, setObectiveStateChangeProps] = React.useState<OKRStateChangeProps | undefined>();

    const { entity, user, objectivesActions, fields } = props;
    const canViewConfiguration = contains(user.permissions.common, CommonOperations.ConfigurationView);

    let moreMenuItems: IContextualMenuItem[] = [];
    const stateMenuItems: IContextualMenuItem[] =
        ObjectivesList.getObjectiveStateMenuItems(entity, entity.attributes.Parent ? { state: entity.parentState!, id: entity.attributes.Parent.id } : undefined, {
            reopen: (entityId: string, state: OKRState) => setObectiveStateChangeProps({ entityId: entityId, actionType: OKRStateChangeAction.Reopen }),
            close: (entityId: string, state: OKRState) => setObectiveStateChangeProps({ entityId: entityId, actionType: OKRStateChangeAction.Close }),
            archive: (entityId: string, state: OKRState) => setObectiveStateChangeProps({ entityId: entityId, actionType: OKRStateChangeAction.Archive }),
            activateToOpen: (entityId: string, state: OKRState) =>
                setObectiveStateChangeProps({ entityId: entityId, actionType: OKRStateChangeAction.ActivateToOpen }),
            activateToClosed: (entityId: string, state: OKRState) =>
                setObectiveStateChangeProps({ entityId: entityId, actionType: OKRStateChangeAction.ActivateToClosed })
        });
    if (canViewConfiguration) {
        moreMenuItems = [...moreMenuItems, {
            key: 'configureLayout',
            iconProps: { iconName: 'ActivateOrders' },
            name: 'Configure Layout',
            onClick: () => setIsLayoutPanelOpen(true)
        }];
    }

    const parentState = entity.parentState ?? OKRState.Open;

    if (entity.isEditable) {
        moreMenuItems = [
            ...moreMenuItems,
            {
                key: 'cloneObjective',
                iconProps: { iconName: 'Copy' },
                name: 'Clone Objective',
                disabled: !canCreate(user.permissions.objective) || parentState !== OKRState.Open,
                onClick: () => { objectivesActions.cloneObjective(entity.id, true) }
            },
            { key: 'objDivider1', itemType: ContextualMenuItemType.Divider },
            ...stateMenuItems,
            { key: 'objDivider2', itemType: ContextualMenuItemType.Divider },
            {
                key: 'deleteObjective',
                iconProps: { iconName: 'Delete' },
                name: 'Delete Objective',
                className: 'more-deleteButton',
                disabled: parentState !== OKRState.Open,
                onClick: () => setIsRemoveDialogOpen(true)
            }];
    }

    const statusField = fields.find(_ => _.name === nameof<IObjectiveAttrs>("Status"))!;

    const objectiveStatusDescriptor = StatusDescriptorFactory.createStatusDescriptor(statusField);
    const statusOption = objectiveStatusDescriptor.getOptionOrDefault(entity.attributes.Status, StatusCategory.NA);

    const updateSections = (updates: Dictionary<Metadata.IUpdateSectionInfo>) => {
        return props.isReadonlyMode
            ? props.objectivesActions.updateSectionsOnClient(updates)
            : props.objectivesActions.updateSections(updates);
    }
    return <>
        <EntityHeader
            entity={entity}
            name={entity.attributes.Name}
            nameTitle={entity.attributes.Name}
            logo={<Logo className={`objective ${objectiveStatesMap[entity.attributes.State].cssClassName}`} />}
            leftSide={<>
                <CategoryColorStatusView
                    statusOption={statusOption}
                    title={Metadata.getLabel(statusField)}
                />
                <StageView entityType={EntityType.Objective} value={entity.attributes.State} className="objective-state" map={objectiveStatesMap} subMenuItems={stateMenuItems} />
            </>}
            buttons={<>
                {(entity.isEditable || canViewConfiguration) && <IconButton
                    menuIconProps={{ iconName: 'More' }}
                    className='menu'
                    text='More'
                    menuProps={{ items: moreMenuItems, className: 'section-menu' }} />}
            </>}
        ></EntityHeader>
        {isLayoutPanelOpen &&
            <EditLayoutPanel
                editType={EditType.Edit}
                layout={{ id: Metadata.DEFAULT_ID, sections: props.sections } as Metadata.Layout}
                onSave={(layout) => updateSections(layout.sections!)}
                onDismiss={() => setIsLayoutPanelOpen(false)} />
        }
        {isRemoveDialogOpen && <ObjectivesToRemoveRemoveDialog
            redirectBack
            onClose={() => setIsRemoveDialogOpen(false)}
            objectivesToRemove={[entity]} />}
        {obectiveStateChangeProps && <ObjectiveStateDialogs
            obectiveStateChangeProps={obectiveStateChangeProps}
            onDismiss={() => setObectiveStateChangeProps(undefined)}
        />}
    </>;
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    const fields = state.fields[EntityType.Objective];
    return {
        user: state.user,
        sections: LayoutService.getDefault(state.layouts[EntityType.Objective]).sections,
        fields: fields.allIds.map(_ => fields.byId[_]),
        hasSubObjectives: state.objectives.allIds.map(id => state.objectives.byId[id]).filter(_ => _.attributes.Parent?.id === ownProps.entity.id).length > 0,
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant)
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps, (dispatch): ActionProps =>
({
    objectivesActions: bindActionCreators(actionCreators, dispatch)
}))(ObjectiveHeader));