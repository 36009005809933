import * as React from 'react';
import { bindActionCreators } from 'redux';
import { RouteComponentProps } from "react-router-dom";
import {
    IColumn, DefaultButton, Selection, IObjectWithKey, DetailsListLayoutMode, IDetailsRowProps, DetailsRow,
    ScrollbarVisibility, ScrollablePane, IDetailsHeaderProps, IRenderFunction, ITooltipHostProps, TooltipHost, StickyPositionType, Sticky, Spinner as FabricSpinner,
    SpinnerSize, IContextualMenuItem, Overlay, MessageBar, MessageBarType, IDialogContentProps, Link, Checkbox, ConstrainMode
} from 'office-ui-fabric-react';
import { IUser, LicensesUtilization } from "../../../store/UsersListStore";
import * as UsersListStore from '../../../store/UsersListStore';
import { Subscription, checkIfADUsersSyncEnabled } from "../../../store/Tenant";
import { connect } from "react-redux";
import { ApplicationState } from "../../../store/index";
import UserEdit from "../UserEdit";
import { DefaultPermissionsPanel } from "../DefaultPermissionsPanel";
import { PermissionsBulkEditPanel } from "../PermissionsBulkEditPanel";
import UserReplace from '../UserReplace';
import Spinner from "../../common/Spinner";
import { UserState, UserStatus } from "../../../store/User";
import { contains, CommonOperations, LicenseTypeMap, LicenseType } from "../../../store/permissions";
import { notUndefined, toCamelCase, toDictionaryById, toDictionaryByName } from '../../utils/common';
import InvitePeople from '../../navigation/InvitePeople';
import * as Metadata from "../../../entities/Metadata";
import { ClearFilterComponent } from '../../common/sectionsControl/SectionPlaceholder';
import {
    AuthProvider, Dictionary, EntityType, IWithSortBy, IWithSubViews, SortDirection,
    getCheckboxOptionsBasedOnCommands
} from '../../../entities/common';
import RemoveDialog from '../../common/RemoveDialog';
import { AlertDialog } from '../../common/AlertDialog';
import { buildAllowedAuthProvidersOptions } from '../AuthProviderSelect';
import SelectionModeSwitchableCommandBar from '../../common/SelectionModeSwitchableCommandBar';
import { MenuTitleBuilder } from '../../MenuTitleBuilder';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import { IDeletionResult } from '../../../store/services/storeHelper';
import { IWithFilter, getClearFilterId } from '../../common/withFilter';
import * as FiltersStore from '../../../store/filters';
import { GetUserEntityFields, UserResourceFields } from '../../../store/user/common';
import UsersFilter from '../UsersFilter';
import { FilterKeys } from '../../../store/filters';
import { BuildUserListColumns } from './UsersListColumnsHelper';
import { nameof } from '../../../store/services/metadataService';
import { FilterHelper } from '../../../store/user/filters';
import './UsersList.css';
import { SortService } from '../../../services/SortService';
import { SearchBox, SearchValue } from '../../common/SearchBox';
import { SearchFieldService } from '../../common/SearchFieldService';
import { PersistSimpleList } from '../../common/extensibleEntity/EntityDetailsList';
import { SortBy } from '../../common/sectionsControl/SectionList';
import { UpdateType, UserViewPreferencesUpdate } from '../../../store/services/viewSaver';
import { saveSettings } from '../../../store/Sections';

type UsersListOwnProps = RouteComponentProps<{}>;
type StateProps = {
    subscription: Subscription;
    allowedAuthProviders?: AuthProvider[];
    authProviders: AuthProvider[];
    users: IUser[];
    currentUser: UserState;
    isLoading: boolean;
    isProcessing: boolean;
    isADUsersSyncEnabled: boolean;

    isUtilizationLoading: boolean;
    licensesUtilization: LicensesUtilization;
    deletionResult: { users: IDeletionResult[]; resources: IDeletionResult[] } | undefined;

    resourceFields: Dictionary<Metadata.Field>;
};

type ActionProps = {
    usersActions: typeof UsersListStore.actionCreators;
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
};

type Props = UsersListOwnProps & StateProps & ActionProps & IWithFilter<IUser>;

interface IUserColumn extends IColumn { fieldName: keyof IUser; }

const UsersList = (props: Props) => {

    React.useEffect(() => {
        props.usersActions.loadUsers();
        props.usersActions.loadLicensesUtilization();
    }, []);

    const [userId, setUserId] = React.useState<string | null>(null);

    const canManage = contains(props.currentUser.permissions.common, CommonOperations.UserManage);
    const [userToReplace, setUserToReplace] = React.useState<IUser | undefined>();
    const [selectedLicense, setSelectedLicense] = React.useState<LicenseType | undefined>();
    const [bulkEdit, setBulkEdit] = React.useState<boolean>();
    const [showRemoveDialog, setShowRemoveDialog] = React.useState<boolean>();
    const [usersToDelete, setUsersToDelete] = React.useState<string[]>([]);
    const [deleteResources, setDeleteResources] = React.useState<boolean>(false);
    const [activationResult, setActivationResult] = React.useState<{ activated: IUser[], notActivated: IUser[] } | undefined>();
    const [confirmUpdateAuthProvider, setConfirmUpdateAuthProvider] = React.useState<{ callback: () => void; count: number; } | undefined>();
    const authProviderOptions = buildAllowedAuthProvidersOptions(props.allowedAuthProviders ?? [])
    const [activeFilter, seAtactiveFilter] = React.useState<Metadata.IFilter<Metadata.BaseFilterValue> | undefined>();
    const [search, setSearch] = React.useState<SearchValue | undefined>();

    const [selectedCount, setSelectedCount] = React.useState(0);

    const _selection = React.useMemo(() => {
        const selection: Selection<IObjectWithKey> = new Selection<IObjectWithKey>({
            canSelectItem: (user: IObjectWithKey) => (user as IUser).id !== props.currentUser.id,
            getKey: _ => (_ as IUser).id,
            onSelectionChanged: () => setSelectedCount(selection.getSelectedCount()),
        });
        return selection;
    }, []);

    const filterHelper = React.useMemo(() =>
        new FilterHelper({
            userFields: GetUserEntityFields(props.authProviders),
            resourceFields: Object.values(props.resourceFields)
        }), []);

    const filteredUsers = React.useMemo(() => {
        const getSearchValue = (user: IUser, field: Metadata.Field) => user[toCamelCase(field.name)] ?? user.resourceAttributes?.[field.name];
        const items = filterHelper.filter(props.users, activeFilter).filter(_ =>
            search?.searchText
                ? SearchFieldService.searchByValue(search, _, getSearchValue)
                : true);
        return items;
    }, [activeFilter, props.users, search]);

    const comparerBuilder = (sortBy: SortBy) => {
        const getValue = props.resourceFields[sortBy.fieldName]
            ? (user: IUser, field: Metadata.Field) => user.resourceAttributes?.[sortBy.fieldName]
            : (user: IUser, field: Metadata.Field) => user?.[sortBy.fieldName];
        return SortService.getOrderByComparerGeneric<IUser>(props.resourceFields[sortBy.fieldName] ? props.resourceFields : toDictionaryByName(filterHelper.UserFields),
            { fieldName: sortBy.fieldName, direction: sortBy.direction, isProperty: !props.resourceFields[sortBy.fieldName] }, getValue);
    }

    const defaulSortBy = {
        fieldName: nameof<IUser>("fullName"),
        direction: SortDirection.ASC
    };

    const _getSelected = (status: UserStatus) => {
        return _selection.getSelection().map(_ => _ as IUser).filter(_ => _.status === status);
    }
    const _getUsersToDelete = () => filteredUsers.filter(_ => usersToDelete.includes(_.id));

    const { currentUser, isProcessing, deletionResult } = props;

    const selectedPendingInviteCount = _getSelected(UserStatus.PendingInvite).length;

    const showDeleteResources = _getUsersToDelete().some(_ => _.status === UserStatus.PendingInvite && _.linkedResource);

    const notDeletedUsers = deletionResult?.users.filter(_ => !_.isDeleted);
    const notDeletedResources = deletionResult?.resources.filter(_ => !_.isDeleted);
    const showDeleteWarning = (notDeletedUsers?.length ?? 0) > 0 || (notDeletedResources?.length ?? 0) > 0;

    const unassign = notDeletedUsers && notDeletedUsers.length > 0 ? <>
        <span>We recommend using the 'Replace User' option to replace {notDeletedUsers.length > 1 ? "these users" : "this user"} and merge their existing data into another user selected as a replacement.
            If 'Replace User' option is used, linked resources and their corresponding data will be merged as well.
            Alternatively, you can unassign or remove {notDeletedUsers.length > 1 ? "these users" : "this user"} from the fields first. </span>
        <Link href="https://help.ppm.express/89502-ppm-express-how-to-articles/637207-how-to-replace-users?from_search=149628615" target="_blank">
            Learn more
        </Link>
    </>
        : (notDeletedResources && notDeletedResources.length > 0 ? <>
            <span>We recommend using the 'Merge Resources' option to replace {notDeletedResources.length > 1 ? "these resources" : "this resource"} on Resources page and merge their existing data into another resource selected as a replacement.
                Alternatively, you can unassign or remove {notDeletedResources.length > 1 ? "these resources" : "this resource"} from the fields first. </span>
            <Link href="https://help.ppm.express/111183-resource-management/resource-merging?from_search=149628615" target="_blank">
                Learn more
            </Link>
        </> : undefined);

    const _getActivationResultDialogContent = (activated: IUser[], notActivated: IUser[]): IDialogContentProps => {
        if (!activated.length && notActivated.length === 1) {
            return {
                title: "Unable to activate user",
                subText: `Failed to activate ${notActivated[0].fullName} "${notActivated[0].logonAccount}" because authentication provider defined in user’s System is turned off \
                    for the organization. Turn on the corresponding authentication provider in the Tenant Settings or invite this user using a different email`
            };
        }

        return activated.length === 1
            ? {
                title: "User activation is complete",
                subText: `User ${activated[0].fullName} "${activated[0].logonAccount}" was activated successfully.`
            }
            : activated.length
                ? {
                    title: "User activation is complete",
                    subText: `Selected users (${activated.length} users) were activated successfully.`
                }
                : {
                    title: "User activation failed"
                };
    }

    const _deleteUsers = () => {
        const pendingInviteUsers = _getUsersToDelete().filter(_ => _.status === UserStatus.PendingInvite);
        props.usersActions.removeUsers(pendingInviteUsers.map(_ => _.id), deleteResources);
        setUsersToDelete([]);
    }

    const _buildRemoveDialogText = () => {
        const pendingInviteUsers = _getUsersToDelete().filter(_ => _.status === UserStatus.PendingInvite);

        if (pendingInviteUsers.length === 1) {
            return `Are you sure you want to delete a user ${pendingInviteUsers[0].fullName}?`;
        }

        return `Are you sure you want to delete selected users (${pendingInviteUsers.length} users)?`;
    }

    const _buildDeleteUsersMessageBar = () => {
        const activatedUsers = _getUsersToDelete().filter(_ => _.status !== UserStatus.PendingInvite);
        if (!activatedUsers.length) {
            return null;
        }
        const message = activatedUsers.length === 1
            ? `${activatedUsers[0].fullName} does not have the "Pending Invite" status and will not be deleted.`
            : `${activatedUsers.length} selected users do not have the "Pending Invite" status and will not be deleted.`;

        return <MessageBar messageBarType={MessageBarType.warning} isMultiline>{message}</MessageBar>
    }

    const _getDeletionResultDialogContent = (delResult: { users: IDeletionResult[], resources: IDeletionResult[] }): IDialogContentProps => {
        if (delResult.users.length === 1 && !delResult.users[0].isDeleted) {
            return {
                title: "Unable to delete user",
            };
        }
        const deletedUsers = delResult.users.filter(_ => _.isDeleted) ?? [];
        return deletedUsers.length === 1
            ? {
                title: "User deletion is complete",
                subText: `User "${deletedUsers[0].name}" was deleted successfully.`
            }
            : deletedUsers.length
                ? {
                    title: "User deletion is complete",
                    subText: `Selected user (${deletedUsers.length} users) were deleted successfully.`
                }
                : {
                    title: "Users deletion failed"
                };
    }

    const _renderNavigation = (): JSX.Element => {
        const { subscription, isUtilizationLoading, licensesUtilization } = props;
        return <div className="page-navigation">
            <InvitePeople />
            <DefaultButton
                className="dropdown-button"
                iconProps={{ iconName: 'Edit' }}
                text='Default Permissions'
                menuProps={{ items: _getDefaultPermissionsItems() }} />
            {subscription.subscriptionId && <div className="align-right licenses-utilization">
                <div>{LicenseTypeMap[LicenseType.Regular].title}s: {isUtilizationLoading
                    ? <FabricSpinner size={SpinnerSize.xSmall} />
                    : <span>{licensesUtilization.users.allocated}/{licensesUtilization.users.total}</span>
                }
                </div>
                {!!subscription.viewersLimit &&
                    <div>{LicenseTypeMap[LicenseType.Viewer].title}s: {isUtilizationLoading
                        ? <FabricSpinner size={SpinnerSize.xSmall} />
                        : <span>{licensesUtilization.viewers.allocated}/{licensesUtilization.viewers.total}</span>
                    }
                    </div>
                }
            </div>}
        </div>;
    }

    const _getDefaultPermissionsItems = () => {
        const { subscription } = props;
        const items = [{
            key: 'regular',
            iconProps: { iconName: 'Edit' },
            text: `${LicenseTypeMap[LicenseType.Regular].title} License`,
            onClick: () => setSelectedLicense(LicenseType.Regular)
        }]

        if (subscription.viewersLimit > 0) {
            items.push({
                key: 'teammember',
                iconProps: { iconName: 'Edit' },
                text: `${LicenseTypeMap[LicenseType.Viewer].title} License`,
                onClick: () => setSelectedLicense(LicenseType.Viewer)
            });
        }

        return items;
    }

    const _setUsersToDelete = () => {
        const selectedUsers = _selection.getSelection().map(_ => (_ as IUser));
        setUsersToDelete(selectedUsers.map(_ => _.id));
        setShowRemoveDialog(true);
        setDeleteResources(selectedUsers.some(_ => _.status === UserStatus.PendingInvite && _.linkedResource));
    }

    const _buildSelectionModeCommands = (): IContextualMenuItem[] => {
        const selectedActiveCount = _getSelected(UserStatus.Active).length;
        const selectedInactiveCount = _getSelected(UserStatus.Inactive).length;

        const { usersActions, isADUsersSyncEnabled } = props;
        return [
            selectedActiveCount > 0 ? {
                key: 'deactivate',
                text: "Deactivate",
                disabled: !canManage || isADUsersSyncEnabled,
                iconProps: { iconName: "Blocked2" },
                onClick: () => usersActions.updateStatuses(_getSelected(UserStatus.Active).map(_ => _.id), UserStatus.Inactive),
            } : undefined,
            selectedInactiveCount > 0 ? {
                key: 'activate-selected',
                text: "Activate Selected",
                disabled: !canManage || isADUsersSyncEnabled,
                iconProps: { iconName: "AddTo" },
                onClick: () => _activate(_getSelected(UserStatus.Inactive))
            } : undefined,
            selectedPendingInviteCount > 0 ? {
                key: 'delete',
                text: "Delete",
                title: MenuTitleBuilder.deleteSelectedTitle("Users"),
                iconProps: { iconName: "Delete" },
                onClick: _setUsersToDelete
            } : undefined,
            {
                key: 'bulk-edit-permissions',
                text: "Bulk Edit Permissions",
                disabled: !canManage || !_selection.getSelection().some(_ => (_ as IUser).license !== LicenseType.None),
                iconProps: { iconName: "TripleColumnEdit" },
                onClick: () => setBulkEdit(true),
            },
            selectedPendingInviteCount > 0 ? {
                key: 'resend-invitation',
                text: "Resend Invitation",
                disabled: !canManage,
                iconProps: { iconName: "Sync" },
                onClick: () => usersActions.resendInvite(_getSelected(UserStatus.PendingInvite).map(_ => _.id)),
            } : undefined,
            {
                key: 'change-authProvider',
                text: "Change Auth Provider",
                disabled: !canManage,
                iconProps: { iconName: 'TemporaryUser' },
                subMenuProps: {
                    items: authProviderOptions,
                    onItemClick: (e: any, item?: IContextualMenuItem) => {
                        item && _updateAuthProvider(_selection.getSelection().map(_ => _ as IUser), item.key as AuthProvider);
                    }
                }
            }
        ].filter(notUndefined);
    }

    const _onRenderDetailsHeader = (p: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element => {
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender!({
                    ...p,
                    onRenderColumnHeaderTooltip: (tooltipHostProps: ITooltipHostProps) => <TooltipHost {...tooltipHostProps} />
                })}
            </Sticky>
        );
    }

    const _onRenderRow = (p: IDetailsRowProps): JSX.Element => {
        return <DetailsRow {...p} className={`${p.className} ${p.item.status === UserStatus.Inactive ? "inactive" : ""}`} />;
    }

    const _activate = (toActivate: IUser[]) => {
        props.usersActions.updateStatuses(toActivate.map(_ => _.id), UserStatus.Active,
            (updatedUsers) => {
                if (toActivate.length === 1 && updatedUsers.length === 1) {
                    setUserId(updatedUsers[0].id);
                } else {
                    setActivationResult(
                        {
                            activated: updatedUsers,
                            notActivated: toActivate.filter(_ => updatedUsers.findIndex(__ => __.id === _.id) === -1)
                        }
                    );
                }
            });
    }

    const _updateAuthProvider = (toUpdate: IUser[], authProvider: AuthProvider) => {
        const callback = () => props.usersActions.updateAuthProvider(toUpdate.map(_ => _.id), authProvider);
        if (!toUpdate.every(_ => _.status === UserStatus.PendingInvite)) {
            setConfirmUpdateAuthProvider({ callback, count: toUpdate.length });
        } else {
            callback();
        }
    }

    const _getFarCommands = (): IContextualMenuItem[] => {
        const fieldsToSearch = filterHelper.UserFields.concat(filterHelper.ResourceFields);
        return [
            {
                key: 'search',
                onRender: () => <SearchBox
                    viewColumns={fieldsToSearch.map(_ => _.id)}
                    fieldsMap={toDictionaryById(fieldsToSearch)}
                    value={search}
                    onSearch={(value) => setSearch(value)}
                />
            },
            {
                key: 'filters',
                onRender: () =>
                    <UsersFilter
                        filterKey={FilterKeys.UsersList}
                        onFilterChange={(filter) => {
                            seAtactiveFilter(filter);
                        }}
                    />
            }
        ];
    }

    const _getItemCommands = (user: IUser): IContextualMenuItem[] => {
        const { isADUsersSyncEnabled } = props;
        return canManage && user.id !== props.currentUser.id
            ? [
                user.status !== UserStatus.Inactive ? {
                    key: "edit",
                    name: "Edit",
                    iconProps: { iconName: 'Edit' },
                    onClick: () => setUserId(user.id)
                } : undefined,
                user.status === UserStatus.PendingInvite ? {
                    key: 'resend',
                    name: 'Resend Invitation',
                    iconProps: { iconName: 'Sync' },
                    onClick: () => { props.usersActions.resendInvite([user.id]); }
                } : undefined,
                {
                    key: 'changeAuthProvider',
                    name: 'Change Auth Provider',
                    iconProps: { iconName: 'TemporaryUser' },
                    subMenuProps: {
                        items: authProviderOptions,
                        onItemClick: (e: any, item?: IContextualMenuItem) => { item && _updateAuthProvider([user], item.key as AuthProvider) }
                    }
                },
                user.status === UserStatus.Active ? {
                    key: 'deactivate',
                    name: 'Deactivate',
                    disabled: isADUsersSyncEnabled,
                    iconProps: { iconName: 'Blocked2' },
                    onClick: () => props.usersActions.updateStatuses([user.id], UserStatus.Inactive)
                } : undefined,
                user.status !== UserStatus.Active ? {
                    key: 'replace',
                    name: 'Replace',
                    iconProps: { iconName: 'UserRemove', style: { color: 'red' } },
                    onClick: () => setUserToReplace(user)
                } : undefined,
                user.status === UserStatus.Inactive ? {
                    key: 'activate',
                    name: 'Activate',
                    disabled: isADUsersSyncEnabled,
                    iconProps: { iconName: 'AddTo' },
                    onClick: () => _activate([user])
                } : undefined,
                user.status === UserStatus.PendingInvite ? {
                    key: 'delete',
                    name: 'Delete',
                    disabled: isADUsersSyncEnabled,
                    style: isADUsersSyncEnabled ? {} : { color: 'red' },
                    iconProps: { iconName: 'Delete', style: isADUsersSyncEnabled ? {} : { color: 'red' } },
                    onClick: _setUsersToDelete
                } : undefined,
            ].filter(notUndefined)
            : [
                contains(props.currentUser.permissions.common, CommonOperations.Administrate)
                    ? {
                        key: "edit",
                        name: "Edit",
                        iconProps: { iconName: 'Edit' },
                        onClick: () => setUserId(user.id)
                    }
                    : {
                        key: "view",
                        name: "View",
                        iconProps: { iconName: 'View' },
                        onClick: () => setUserId(user.id)
                    }
            ];
    }

    const selectionModeCommands = React.useMemo(() => _buildSelectionModeCommands(), [_selection.getSelectedCount()]);
    const columns = React.useMemo(() => {
        const columnProps = {
            subscription: props.subscription,
            userResourceFields: props.resourceFields,
            usersActions: props.usersActions,
            getItemCommands: _getItemCommands,
            onSelectUser: setUserId
        };
        return BuildUserListColumns(columnProps);
    }, [canManage, props.isADUsersSyncEnabled]);

    const [userSettings, setUserSettings] = React.useState<IWithSortBy & IWithSubViews>({});
    const onSaveSettings = (update: UserViewPreferencesUpdate<UpdateType>, sendToServer?: boolean) => {
        const controlSettings = saveSettings({ settings: userSettings }, update);
        setUserSettings(controlSettings.settings);
    }

    if (props.isLoading) {
        return <Spinner />;
    }

    return <div className="user-management">
        {_renderNavigation()}
        <h2>People management</h2>
        <div className="entities-list">
            <div className='entities-list-header'>
                <SelectionModeSwitchableCommandBar
                    items={[]}
                    farItems={_getFarCommands()}
                    entityTypeLabels={{ singular: 'person', plural: 'people' }}
                    selectionMode={{
                        enabled: selectedCount > 0,
                        selectedCount,
                        items: selectionModeCommands,
                        onCancel: () => _selection.setAllSelected(false),
                    }}
                />
            </div>
            <ClearFilterComponent items={props.users} filteredItems={filteredUsers}
                onClearFilter={() => props.filtersActions.setActiveFilter(getClearFilterId(EntityType.User))}
                search={search}>
                <div className="entities-list-body list-container">
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <PersistSimpleList
                            className="editable-grid"
                            setKey='set'
                            constrainMode={ConstrainMode.unconstrained}
                            layoutMode={DetailsListLayoutMode.justified}
                            items={filteredUsers}
                            columns={columns}
                            selection={_selection}
                            comparerBuilder={comparerBuilder}
                            onRenderRow={_onRenderRow}
                            defaulSortBy={defaulSortBy}
                            userSettings={userSettings}
                            onSaveSettings={onSaveSettings}
                            onRenderDetailsHeader={_onRenderDetailsHeader}
                            {...getCheckboxOptionsBasedOnCommands(selectionModeCommands, true)}
                        />
                    </ScrollablePane>
                    {userId && <UserEdit
                        readOnly={!canManage || (userId === currentUser.id && !contains(currentUser.permissions.common, CommonOperations.Administrate))}
                        isCurrentUser={userId === currentUser.id}
                        onDismiss={() => setUserId(null)}
                        userId={userId} />}
                    {selectedLicense !== undefined && <DefaultPermissionsPanel
                        readOnly={!canManage}
                        license={selectedLicense}
                        onDismiss={() => setSelectedLicense(undefined)} />}
                    {bulkEdit && <PermissionsBulkEditPanel
                        ids={_selection.getSelection().map(_ => (_ as IUser).id)}
                        counters={_selection.getSelection()
                            .reduce((cum, cur) => ({
                                ...cum,
                                [(cur as IUser).license as number]: ((cum[(cur as IUser).license as number] ?? 0) + 1)
                            }),
                                {} as { [i: number]: number })}
                        onDismiss={() => setBulkEdit(undefined)} />}
                </div>
            </ClearFilterComponent>
        </div>
        {showRemoveDialog && <RemoveDialog
            onClose={() => setShowRemoveDialog(false)}
            onComplete={() => _deleteUsers()}
            dialogContentProps={{
                title: "Delete users",
                subText: _buildRemoveDialogText(),
                className: "remove-user-dialog-content"
            }}
            confirmButtonProps={{ text: "Delete" }}>
            {usersToDelete.length > 1 && selectedCount > selectedPendingInviteCount && _buildDeleteUsersMessageBar()}
            <MessageBar messageBarType={MessageBarType.info} isMultiline>
                If a user has assignments in any default or custom fields of the 'User' type,
                this user will not be deleted until those assignments are removed. However,
                if a resource linked to this user has assignments, this resource and its assignments will be preserved,
                allowing the user to be deleted.
            </MessageBar>
            {showDeleteResources && <DeleteResourcesCheckBox usersToDelete={usersToDelete} deleteResources={deleteResources} setDeleteResources={setDeleteResources} />}
        </RemoveDialog>}
        {deletionResult && ((notDeletedUsers && notDeletedUsers.length > 0) || (notDeletedResources && notDeletedResources.length > 0)) && <RemoveDialog
            onClose={() => { props.usersActions.dismissDeletionResult() }}
            confirmButtonProps={{ text: "Got it" }}
            modalProps={{ styles: { main: { minWidth: 500 } } }}
            dialogContentProps={_getDeletionResultDialogContent(deletionResult)}>
            {showDeleteWarning && <div className="message-bar-holder">
                {notDeletedUsers && notDeletedUsers.length > 0 && <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    Failed to delete the following {notDeletedUsers.length > 1 ? `users ${deleteResources ? "with linked resources" : ""} as they are` : `user ${deleteResources ? "with linked resource" : ""} as the user is`} in use:
                    <ul>
                        {notDeletedUsers.map(_ => <li key={_.id}>{_.message}</li>)}
                    </ul>
                </MessageBar>
                }
                {notDeletedResources && notDeletedResources.length > 0 && <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    Failed to delete the following associated {notDeletedResources.length > 1 ? "resources as they are" : "resource as the resource is"} in use:
                    <ul>
                        {notDeletedResources.map(_ => <li key={_.id}>{_.message}</li>)}
                    </ul>
                </MessageBar>
                }
            </div>}
            {unassign && <MessageBar messageBarType={MessageBarType.info} isMultiline>
                {unassign}
            </MessageBar>}
        </RemoveDialog>}
        {userToReplace && <UserReplace prevUser={userToReplace} onDismiss={() => setUserToReplace(undefined)} />}
        {activationResult && <AlertDialog
            onDismiss={() => setActivationResult(undefined)}
            dialogContentProps={_getActivationResultDialogContent(activationResult.activated, activationResult.notActivated)}>
            {(activationResult.activated.length && activationResult.notActivated.length === 1 || activationResult.notActivated.length > 1) &&
                <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    Failed to activate the following users because authentication provider defined in users’ System is turned off for the organization.
                    Turn on the corresponding authentication provider in the Tenant Settings or invite these users using a different email:
                    <ul>
                        {activationResult.notActivated.map(_ => <li key={_.id}>{_.fullName} "{_.logonAccount}"</li>)}
                    </ul>
                </MessageBar>}
        </AlertDialog>}
        {confirmUpdateAuthProvider && <ConfirmationDialog
            onDismiss={() => setConfirmUpdateAuthProvider(undefined)}
            onYes={confirmUpdateAuthProvider.callback}
            onNo={() => { }}
            yesButtonProps={{ text: "Confirm" }}
            noButtonProps={{ text: "Cancel" }}
            dialogContentProps={{
                title: 'Change Authentication Provider',
                subText: `Are you sure that you want to change authentication provider for selected ${confirmUpdateAuthProvider.count > 1
                    ? `users? (${confirmUpdateAuthProvider.count} users)`
                    : "user?"}`
            }} >
            <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                Selected users with an 'Active' or 'Inactive' status will have their status changed to 'Pending Invite'.<br />
                The authentication provider will be updated for these users, and an invitation email will be sent
                to allow them to rejoin PPM Express with the updated authentication provider.<br />
                <br />
                For Active Users, their license type, permissions, and assignments will remain unchanged.<br />
                For Inactive Users, their assignments will be preserved, and upon rejoining the tenant, they will be assigned a default license with default permissions,
                unless a specific license and permissions have already been set for them.
            </MessageBar>
        </ConfirmationDialog>}
        {isProcessing && <Overlay><Spinner /></Overlay>}
    </div>;
}

const DeleteResourcesCheckBox = (props: { usersToDelete: string[], deleteResources: boolean, setDeleteResources: (value: boolean) => void }) => <>
    <Checkbox
        className="delete-resources-check"
        label={props.usersToDelete.length > 1 ? "Delete Linked Resources" : "Delete Linked Resource"}
        checked={props.deleteResources}
        onChange={(e, c) => props.setDeleteResources(!!c)} />
    <MessageBar messageBarType={MessageBarType.info} isMultiline>
        Resources linked to the selected users will also be deleted unless they have assignments in
        any default or custom 'Resource' fields. If a resource has assignments, this resource will not be
        deleted until those assignments are removed.
    </MessageBar>
</>


function mapStateToProps(state: ApplicationState, ownProps: UsersListOwnProps): StateProps {
    const byId = state.users.byId;
    return {
        subscription: state.tenant.subscription,
        allowedAuthProviders: state.tenant.security?.allowedAuthProviders,
        users: state.users.allIds.map(_ => byId[_]),
        resourceFields: toDictionaryByName(Object.values(UserResourceFields).map(_ => state.fields.resource.byId[_])),
        currentUser: state.user,
        isADUsersSyncEnabled: checkIfADUsersSyncEnabled(state.tenant),
        isLoading: state.users.isLoading || !state.users.isLoaded,
        isProcessing: state.users.isProcessing || state.tenant.isProcessing,
        licensesUtilization: state.users.licensesUtilization,
        isUtilizationLoading: state.users.isUtilizationLoading,
        deletionResult: state.users.deletionResult,
        authProviders: state.enviroment.authProviders
    }
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        usersActions: bindActionCreators(UsersListStore.actionCreators, dispatch),
        filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(EntityType.User, FilterKeys.UsersList), dispatch),
    }
}

export default connect(mapStateToProps, mergeActionCreators)(UsersList);
