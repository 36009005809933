import * as React from 'react';
import { CommonOperations, contains, IPermissions, LicenseType, LicenseTypeMap, ResourceOperations, toggle } from "../../store/permissions";
import { IDropdownOption, Dropdown, MessageBarType, MessageBar } from 'office-ui-fabric-react';
import { CommonPermissions, isCommonPermissionChecked, ObjectivesAccessLevel } from './CommonPermissions';
import { ResourcePermissions } from './ResourcePermissions';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import Link from '../common/Link';

type OwnProps = {
    license: LicenseType;
    readOnly?: boolean;
    isCurrentUser?: boolean;
    permissions: IPermissions;
    onChange: (license: LicenseType, changes: Partial<IPermissions>) => void;
    errorMessage?: string;
    hideLicenseSelector?: boolean;
    disableLicenseSelector?: boolean;
    hideGranularEntityPermissions?: boolean;
}
type StateProps = {
    viewersLimit?: number;
}
type Props = StateProps & OwnProps;

const PermissionsEdit = (props: Props) => {
    const { permissions, readOnly, license, isCurrentUser, hideLicenseSelector, disableLicenseSelector, hideGranularEntityPermissions, viewersLimit } = props;

    return <div className="permissions-edit">
        {
            !hideLicenseSelector && <Dropdown
                label="License"
                disabled={readOnly || isCurrentUser || disableLicenseSelector}
                selectedKey={license}
                options={getLicences(viewersLimit)}
                onChange={(e, _) => props.onChange(_!.key as LicenseType, {})}
                errorMessage={props.errorMessage}
            />
        }
        {license != LicenseType.None && <div>
            <HelpInfo />
            <CommonPermissions
                licenseType={license}
                disabled={_ => readOnly
                    || (_.operation === CommonOperations.Administrate ? isCurrentUser : contains(permissions.common, CommonOperations.Administrate))
                    || (_.objectives === ObjectivesAccessLevel.View && contains(permissions.objective.global, ResourceOperations.Update))}
                checked={_ => isCommonPermissionChecked(_, permissions)}
                onChange={(arg, c) => {
                    const change: Partial<IPermissions> = {};
                    if (arg.operation !== undefined) {
                        change.common = toggle(permissions.common, arg.operation, c);
                    }
                    if (arg.objectives) {
                        change.objective = {
                            ...permissions.objective,
                            global: toggle(permissions.objective.global,
                                arg.objectives === ObjectivesAccessLevel.View
                                    ? ResourceOperations.Create | ResourceOperations.Read | ResourceOperations.Collaborate
                                    : c ? ResourceOperations.Create | ResourceOperations.Read | ResourceOperations.Collaborate | ResourceOperations.Update : ResourceOperations.Update,
                                c)
                        };
                    }
                    _onChange(change);
                }}
            />
            <ResourcePermissions
                license={license}
                readOnly={readOnly}
                permissions={permissions}
                onChange={_onChange}
                hideGranularEntityPermissions={hideGranularEntityPermissions}
            />
        </div>}
    </div>;

    function _onChange(changes: Partial<IPermissions>) {
        props.onChange(license, changes);
    }
}

export function getLicences(viewersLimit: number | undefined): IDropdownOption[] {
    let licenceTypes = [LicenseType.None, LicenseType.Regular];

    if (!!viewersLimit) {
        licenceTypes.push(LicenseType.Viewer);
    }

    return licenceTypes.map(_ => ({ key: _, text: LicenseTypeMap[_].title } as IDropdownOption))!;
}

export const HelpInfo = (props: React.PropsWithChildren<{}>) => <MessageBar messageBarType={MessageBarType.info}>To find more details on each permission, please refer to
    {' '}<Link target="_blank" href="https://help.ppm.express/89495-ppm-express-settings/people-management">this</Link> article.
    {props.children ? <br /> : undefined}
    {props.children}</MessageBar>;

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps => ({ viewersLimit: state.tenant.subscription.viewersLimit })
export default connect(mapStateToProps)(PermissionsEdit);