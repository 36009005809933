import { IEntityStore, StoreHelper, partialUpdate, IDeletionResult } from "./services/storeHelper";
import { AppThunkAction } from "./";
import { get, post } from "../fetch-interceptor";
import { Action, Reducer } from 'redux';
import { UserStatus } from "./User";
import { LicenseType, IPermissions, IServerPermissions, toPermissions, toServerPermissions } from "./permissions";
import { defaultCatch } from "./utils";
import * as NotificationsStore from "./NotificationsStore";
import { ResourceStatus, ResourcesStatusesUpdatedAction } from './ResourcesListStore';
import { AuthProvider, Dictionary, EnumConfigMap } from "../entities/common";

export interface IUser {
    id: string;
    fullName: string;
    imageId?: string;
    email: string;
    status: UserStatus;
    system: string;
    authProvider?: AuthProvider;
    logonAccount: string;
    permissions?: IPermissions;
    license: LicenseType;
    linkedResource?: PersonInfo;
    resourceAttributes?: Dictionary<any>;
}

export type PersonInfo = {
    id: string;
    fullName: string;
    imageId?: string;
}

export const UserStatusMap: EnumConfigMap = {
    [UserStatus.Active]: { title: "Active", cssClassName: "Active" },
    [UserStatus.Inactive]: { title: "Inactive", cssClassName: "Inactive" },
    [UserStatus.PendingInvite]: { title: "Pending Invite", cssClassName: "PendingInvite" }
}

export interface UsersState extends IEntityStore<IUser> {
    isLoading: boolean;
    isLoaded: boolean;
    isProcessing: boolean;
    licensesUtilization: LicensesUtilization;
    isUtilizationLoading: boolean;
    deletionResult?: { users: IDeletionResult[]; resources: IDeletionResult[] };
}

export type LicensesUtilization = {
    users: LicenseUtilization;
    viewers: LicenseUtilization;
}

type LicenseUtilization = {
    total: number;
    allocated: number;
}

const unloadedState: UsersState = {
    allIds: [],
    byId: {},
    isLoading: false,
    isLoaded: false,
    isProcessing: false,
    licensesUtilization: { users: { total: 0, allocated: 0 }, viewers: { total: 0, allocated: 0 } },
    isUtilizationLoading: false
}

type LoadUsersAction = { type: 'LOAD_USERS'; }
type UsersLoadedAction = { type: 'USERS_LOADED'; users: IUser[]; }
type UserPermissionsSavedAction = { type: "USER_PERMISSIONS_SAVED"; userId: string; license: LicenseType; permissions: IServerPermissions; }
type StatusesUpdatedAction = { type: 'USERS_STATUSES_UPDATED'; users: IUser[]; }
type AuthProviderUpdatedAction = { type: 'USERS_UPDATED'; users: IUser[]; }
type UserPermissionsLoadedAction = { type: 'USER_PERMISSIONS_LOADED'; permissions: IServerPermissions; userId: string; license: LicenseType; }
type UsersInvitedAction = { type: 'USERS_INVITED'; users: IUser[]; }
type ReceivedDeleteUsersResultAction = { type: 'RECEIVED_REMOVE_USERS_RESULT'; users?: IDeletionResult[]; resources?: IDeletionResult[]; }
type LoadLicensesUtilizationAction = { type: "USERS_LOAD_LICENSES_UTILIZATION"; }
type LicensesUtilizationLoadedAction = { type: "USERS_LICENSES_UTILIZATION_LOADED"; utilization: LicensesUtilization; }
type BeginProcessingAction = { type: 'USERS_BEGIN_PROCESSING' }
type BulkPermissionsUpdatedAction = { type: 'USERS_BULK_PERMISSIONS_UPDATED'; users: IUser[]; }

type KnownAction = LoadUsersAction
    | UsersLoadedAction
    | UserPermissionsSavedAction
    | StatusesUpdatedAction
    | AuthProviderUpdatedAction
    | UserPermissionsLoadedAction
    | UsersInvitedAction
    | ReceivedDeleteUsersResultAction
    | LoadLicensesUtilizationAction
    | LicensesUtilizationLoadedAction
    | BeginProcessingAction
    | BulkPermissionsUpdatedAction;

const licenseActions = {
    loadLicensesUtilization: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<LicensesUtilization>(`api/user/license`)
            .then(data => dispatch({ type: "USERS_LICENSES_UTILIZATION_LOADED", utilization: data }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: "USERS_LOAD_LICENSES_UTILIZATION" });
    }
}

export const actionCreators = {
    loadUsers: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<IUser[]>(`api/user`)
            .then(_ => dispatch({ type: 'USERS_LOADED', users: _ }))
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'LOAD_USERS' });
    },
    updateStatuses: (userIds: string[], status: UserStatus, callback?: (users: IUser[]) => void):
        AppThunkAction<KnownAction | ResourcesStatusesUpdatedAction> => (dispatch, getState) => {
            if (!userIds.length) {
                return
            }
            post<{ users: IUser[], resourceIds: string[] }>(`api/user/status`, { ids: userIds, status })
                .then(_ => {
                    dispatch({ type: 'USERS_STATUSES_UPDATED', users: _.users });
                    dispatch({
                        type: 'RESOURCES_STATUSES_UPDATED', resourceIds: _.resourceIds, status: status === UserStatus.Active ? ResourceStatus.Active : ResourceStatus.Inactive
                    });
                    dispatch(licenseActions.loadLicensesUtilization());
                    callback?.(_.users);
                })
                .catch(defaultCatch(dispatch));
        },
    updateAuthProvider: (userIds: string[], authProvider?: AuthProvider):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            if (!userIds.length) {
                return
            }
            post<IUser[]>(`api/user/authProvider`, { ids: userIds, authProvider })
                .then(_ => dispatch({ type: 'USERS_UPDATED', users: _ }))
                .catch(defaultCatch(dispatch));
        },
    updateResourceAttributes: (models: { id: string, resourceAttributes?: Dictionary<unknown> }[]):
        AppThunkAction<KnownAction> => (dispatch, getState) => {
            post<IUser[]>(`api/user/resource/attributes`, models)
                .then(_ => dispatch({ type: 'USERS_UPDATED', users: _ }))
                .catch(defaultCatch(dispatch));
        },
    replace: (prevUserId: string, nextUserId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<unknown>(`api/user/replace`, { prevUserId, nextUserId })
            .then(_ => {
                dispatch({ type: "RECEIVED_REMOVE_USERS_RESULT", users: [{ id: prevUserId, isDeleted: true, name: '' }] });
                dispatch(licenseActions.loadLicensesUtilization());
            })
            .catch(defaultCatch(dispatch));
    },
    loadPermissions: (userId: string, license: LicenseType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<IServerPermissions>(`api/user/${userId}/permissions`)
            .then(_ => { dispatch({ type: 'USER_PERMISSIONS_LOADED', userId: userId, license, permissions: _ }); })
            .catch(defaultCatch(dispatch));
    },
    savePermissions: (userId: string, license: LicenseType, permissions: IPermissions, callback: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<IServerPermissions>(`api/user/${userId}/permissions`, { license, permissions: toServerPermissions(permissions) })
            .then(_ => {
                dispatch({ type: 'USER_PERMISSIONS_SAVED', userId: userId, license: license, permissions: _ });
                dispatch(licenseActions.loadLicensesUtilization());
                callback();
            })
            .catch(defaultCatch(dispatch));
    },
    bulkSavePermissions: (ids: string[], addPermissions: IPermissions, removePermissions: IPermissions): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'USERS_BEGIN_PROCESSING' });

        post<IUser[]>(`api/user/permissions`, { ids, addPermissions: toServerPermissions(addPermissions), removePermissions: toServerPermissions(removePermissions) })
            .then(_ => dispatch({ type: 'USERS_BULK_PERMISSIONS_UPDATED', users: _ }))
            .catch(defaultCatch(dispatch));
    },
    invite: (emails: string[], license: LicenseType, permissions: IPermissions, authProvider?: AuthProvider):
        AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
            post<IUser[]>(`api/user/invite`, { emails, authProvider, license, permissions: toServerPermissions(permissions) })
                .then(_ => {
                    const pluralSuffix = emails.length > 1 ? 's' : '';
                    dispatch(NotificationsStore.actionCreators.pushNotification({
                        message: _.length == 0
                            ? `No invitation${pluralSuffix} sent, user${pluralSuffix} already exist${pluralSuffix ? '' : 's'}.`
                            : `${_.length} invitation${pluralSuffix} successfully sent`
                    }));
                    dispatch({ type: 'USERS_INVITED', users: _ });
                    dispatch(licenseActions.loadLicensesUtilization());
                })
                .catch(defaultCatch(dispatch));
        },
    resendInvite: (ids: string[]): AppThunkAction<KnownAction | NotificationsStore.KnownAction> => (dispatch, getState) => {
        post<IUser[]>(`api/user/resendInvite`, { ids })
            .then(_ => {
                dispatch(NotificationsStore.actionCreators.pushNotification({
                    message: `Invitation is successfully sent to ${_.length === 1 ? _[0].email : _.length + ' users'}`
                }));
            })
            .catch(defaultCatch(dispatch));
    },
    removeUsers: (ids: string[], deleteLinkedResources: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<{ users: IDeletionResult[], resources: IDeletionResult[] }>('api/user/delete', { ids, deleteLinkedResources })
            .then(data => {
                dispatch({ type: "RECEIVED_REMOVE_USERS_RESULT", users: data.users, resources: data.resources });
                dispatch(licenseActions.loadLicensesUtilization());
            })
            .catch(defaultCatch(dispatch));
        dispatch({ type: "LOAD_USERS" });
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_USERS_RESULT" });
    },
    ...licenseActions
}

export const reducer: Reducer<UsersState> = (state: UsersState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'LOAD_USERS':
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            }
        case 'USERS_LOADED':
            return {
                ...state,
                ...StoreHelper.create(action.users),
                isLoading: false,
                isLoaded: true
            }
        case 'USER_PERMISSIONS_SAVED': {
            return StoreHelper.applyHandler(state, action.userId,
                (user: IUser) => partialUpdate(user, {
                    license: action.license,
                    permissions: toPermissions(action.permissions, action.userId, action.license)
                }));
        }
        case 'USERS_STATUSES_UPDATED':
        case 'USERS_UPDATED':
            return {
                ...state,
                ...StoreHelper.union(state, action.users),
            };
        case 'USER_PERMISSIONS_LOADED': {
            return StoreHelper.applyHandler(state, action.userId,
                (user: IUser) => partialUpdate(user, { permissions: toPermissions(action.permissions, action.userId, action.license) }));
        }
        case 'USERS_INVITED':
            return {
                ...state,
                ...StoreHelper.union(state, action.users),
                isLoading: false
            }
        case 'RECEIVED_REMOVE_USERS_RESULT':
            let usersStore = {};
            action.users?.forEach(deletion => {
                if (deletion?.isDeleted) {
                    usersStore = StoreHelper.remove(state, deletion.id);
                }
            });
            return {
                ...state,
                ...usersStore,
                isLoading: false,
                isLoaded: true,
                deletionResult: { users: action.users ?? [], resources: action.resources ?? [] }
            };
        case "USERS_LOAD_LICENSES_UTILIZATION": return {
            ...state,
            isUtilizationLoading: true
        };
        case "USERS_LICENSES_UTILIZATION_LOADED": return {
            ...state,
            licensesUtilization: action.utilization,
            isUtilizationLoading: false
        }
        case 'USERS_BEGIN_PROCESSING': return {
            ...state,
            isProcessing: true
        }
        case "USERS_BULK_PERMISSIONS_UPDATED": return {
            ...state,
            ...StoreHelper.union(state, action.users),
            isProcessing: false
        }
        default:
            const exhaustiveCheck: never = action;
    }

    return state;
}