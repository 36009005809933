
import { IconButton } from "office-ui-fabric-react";
import React from "react";
import { FlowChartItem, GetChartItemStyles, ItemCard } from "./ItemCard";
import { notNull } from "../../../utils/common";

type Props = {
    group: FlowChartGroup;
    items: FlowChartItem[];
    activeItem?: FlowChartItem;
    systemItem?: FlowChartItem;
    isExpandedDefault?: boolean;
    className: string;
    getChartItemStyles: GetChartItemStyles;
}

export const GroupCard = React.forwardRef<GroupCardRef, Props>((props, ref) => {
    const { group, items, activeItem, systemItem, isExpandedDefault, className, getChartItemStyles } = props;
    const refs = React.useRef(Array.of<HTMLDivElement>());
    const stagesRef = React.useRef<HTMLDivElement>(null);
    const activeItemRef = React.useRef<HTMLDivElement>(null);

    const [isCompact, setIsCompact] = React.useState(!isExpandedDefault);
    const systemAtItemIndex = isCompact ? (activeItem && activeItem?.id === systemItem?.id ? 0 : -1) : items.findIndex(_ => _.id === systemItem?.id);

    React.useImperativeHandle(ref, () => ({
        get stagesRefs() { return (isCompact ? (activeItem ? [activeItemRef.current] : [stagesRef.current]) : refs.current).filter(notNull) },
        get systemAtIndex() { return systemAtItemIndex },
    }));

    return <div className={`flow-chart-group-card ${className} ${isCompact ? 'compact' : ''}`} style={{ borderColor: group.color }}>
        <div className="flow-chart-group-card-header">
            <div className="flow-chart-group-card-header-title overflow-text" title={group.name}>{group.name}</div>
            <div className="flow-chart-group-card-header-info">
                <div className="flow-chart-group-card-header-bubble">{items.length}</div>
                {
                    isCompact
                        ? <IconButton iconProps={{ iconName: "DoubleChevronRight" }} title="Expand" onClick={() => setIsCompact(false)} />
                        : <IconButton iconProps={{ iconName: "DoubleChevronLeft" }} title="Collapse" onClick={() => setIsCompact(true)} />
                }
            </div>
        </div>
        <div className="flow-chart-group-card-stages" ref={stagesRef}>
            {!isCompact && items.map((_, index) => <ItemCard key={_.id} ref={el => refs.current[index] = el as any} item={_} getChartItemStyles={getChartItemStyles} />)}
            {isCompact && activeItem && <ItemCard ref={activeItemRef} item={activeItem} getChartItemStyles={getChartItemStyles} />}
        </div>
    </div>
});

export type GroupCardRef = {
    stagesRefs: HTMLDivElement[],
    systemAtIndex: number
}

export type FlowChartGroup = { name: string, color: string }