import * as React from 'react';
import { cancellableGet } from "../../fetch-interceptor";
import { EntityProcessInfo } from "../../store/ProjectsListStore"
import EntityPicker from '../common/inputs/EntityPicker';
import { IFindResult } from '../common/inputs/EntityPickerInput';
import LabellableComponent from '../common/LabellableComponent';
import OverlayComponent from '../common/OverlayComponent';
import { ServerEntityType } from '../../entities/common';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { Validator } from '../../validation';

export type ProjectProcessFieldProps = {
    processInfo: EntityProcessInfo | undefined;
    setProcessInfo: (processInfo: EntityProcessInfo | undefined) => void;
    updateIsDirty: (isDirty: boolean) => void;
}

export const validators = {
    process: Validator.new().required().build()
};

const ProjectProcessField = (props: ProjectProcessFieldProps) => {
    const { processInfo, setProcessInfo, updateIsDirty } = props;
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        const request = cancellableGet<EntityProcessInfo>('api/process/default');
        let isCancelled = false;
        setIsLoading(true);
        request.promise
            .then(_ => setProcessInfo({ id: _.id, name: _.name, entityLayout: _.entityLayout }))
            .catch(_ => {
                if (!isCancelled) {
                    console.error(_);   
                }
            })
            .finally(() => setIsLoading(false));
        
        return () => {
            request.cancelTokenSource.cancel();
            isCancelled = true;
        };        
    }, []);

    return (
        <LabellableComponent className="field-container spinner-container" label="Process" required>
            <OverlayComponent isOverlayed={isLoading}>
                <EntityPicker
                    value={processInfo}
                    searchPath="api/process/find"
                    filter={{ entityType: ServerEntityType.Project }}
                    inputProps={{ required: true }}
                    onEditComplete={_ => {
                        const result = _ as IFindResult;
                        if (!result) {
                            setProcessInfo(undefined);
                            updateIsDirty(true);
                            return;
                        }
                        setProcessInfo({ id: result.id, name: result.name, entityLayout: result.attributes?.EntityLayout });
                        updateIsDirty(false);
                    }}
                />
            </OverlayComponent>
            {isLoading && <Spinner size={SpinnerSize.small} style={{ marginTop: -46, width: 30, position: 'absolute' }} />}
        </LabellableComponent>
    )
}

export default ProjectProcessField;