import * as React from 'react';
import { IListViewFieldColumn } from "../../../../../services/ViewService";
import { ProjectInfo } from "../../../../../store/ProjectsListStore";
import { Field } from "../../../../../entities/Metadata"; 
import { TextFormatter } from '../../../../common/formatters/TextFormatter';

export const getValue = (entity: ProjectInfo, field: Field): string => entity.process.name;

const ProcessFormatter = (props: IListViewFieldColumn<ProjectInfo>) => 
    <TextFormatter {...props} value={props.entity.process.name} />;

export function getSearchValue(entity: ProjectInfo, field: Field) {
    return entity.process?.name;
}

export default ProcessFormatter;