import React from "react";
import { IContextualMenuItem, Icon, IconButton } from "office-ui-fabric-react";
import { EntityType, entityTypeLabelMap } from "../../../../entities/common";

export type ColorStageViewProps = {
    fieldLabel?: string;
    title?: string;
    className?: string;
    rootClassName?: string;
    iconName?: string;
    subMenuItems?: IContextualMenuItem[];
    style?: React.CSSProperties;
}

export const getStageFieldLabel = (entityType?: EntityType, fieldLabel?: string) => {
    if (fieldLabel) {
        return fieldLabel;
    }
    if (!entityType) {
        return undefined;
    }
    switch (entityType) {
        case EntityType.Process:
            return `Process Status`;
        case EntityType.Objective:
            return `Objective State`
        default:
            return `${entityTypeLabelMap[entityType].singular} Stage`
    }
}

const _getTitle = (fieldLabel?: string, title?: string) => {
    if (!fieldLabel) {
        return title;
    }
    return `${fieldLabel} (${title})`;
}

const ColorStageView = React.memo((props: ColorStageViewProps) => <div className={`color-stage ${props.rootClassName || ''}`}>
    <div className={`status ${props.className ? props.className : ''}`} title={_getTitle(props.fieldLabel, props.title)}>
        <div className="align-center details" style={props.style}>
            {props.iconName ? <Icon iconName={props.iconName} className="status-icon" /> : undefined}
            <span className="status-label">{props.title}</span>
        </div>
        {props.subMenuItems && props.subMenuItems.length > 0 && <IconButton
            menuIconProps={{ iconName: 'ChevronDown' }}
            className='menu'
            menuProps={{ items: props.subMenuItems, className: 'section-menu' }} />}
    </div>
</div>);

export default ColorStageView;